import { Container, Grid } from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Card from "@mui/material/Card";
import MKButton from "components/MKButton";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import routes from "routes";
import footerRoutes from "footer.routes";

import bgImage from "assets/images/bg-about-us.jpg";
import { TypeAnimation } from "react-type-animation";
import { useNavigate } from "react-router-dom";

function KnowledgeCenter() {
    const navigate = useNavigate();
    return (
        <>
            <DefaultNavbar
                routes={routes}
                transparent
                light
            />
            <MKBox
                minHeight="75vh"
                width="100%"
                sx={{
                    backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <Container>
                    <Grid
                        container
                        item
                        xs={12}
                        lg={8}
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        sx={{ mx: "auto", textAlign: "center" }}
                    >
                        <MKTypography
                            variant="h1"
                            color="white"
                            sx={({ breakpoints, typography: { size } }) => ({
                                [breakpoints.down("md")]: {
                                    fontSize: size["3xl"],
                                },
                            })}
                        >
                            <TypeAnimation
                                preRenderFirstString={true}
                                sequence={[
                                    500,
                                    'Welcome to IMCHESS Academy Knowledge Center', // initially rendered starting point
                                    1000,
                                    'Welcome to IMCHESS Academy Knowledge Center',
                                    1000,
                                    'Welcome to IMCHESS Academy Knowledge Center',
                                    1000,
                                    'Welcome to IMCHESS Academy Knowledge Center',
                                    500,
                                ]}
                                speed={50}
                                // style={{ fontSize: '2em' }}
                                repeat={Infinity}
                            />
                        </MKTypography>
                        <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
                            We&apos;re here to help you!
                        </MKTypography>
                        <MKButton color="default" onClick={() => navigate('/contact-us')} sx={{ color: ({ palette: { dark } }) => dark.main }}>
                            Join Now
                        </MKButton>
                        <MKTypography variant="h6" color="white" mt={8} mb={1}>
                            Find us on
                        </MKTypography>
                        <MKBox display="flex" justifyContent="center" alignItems="center">
                            <MKTypography component="a" variant="body1" color="white" href="#" mr={3}>
                                <i className="fab fa-facebook" />
                            </MKTypography>
                            <MKTypography component="a" variant="body1" color="white" href="#" mr={3}>
                                <i className="fab fa-instagram" />
                            </MKTypography>
                            <MKTypography component="a" variant="body1" color="white" href="#" mr={3}>
                                <i className="fab fa-twitter" />
                            </MKTypography>
                            <MKTypography component="a" variant="body1" color="white" href="#">
                                <i className="fab fa-youtube" />
                            </MKTypography>
                        </MKBox>
                    </Grid>
                </Container>
            </MKBox>
            <Card
                sx={{
                    p: 2,
                    mx: { xs: 2, lg: 3 },
                    mt: -8,
                    mb: 4,
                    boxShadow: ({ boxShadows: { xxl } }) => xxl,
                }}
            >
                <MKBox component="section">
                    <Container>
                        <Grid container spacing={3} alignItems="center">
                            <Grid item xs={12} lg={12}>
                                <Grid container justifyContent="flex-start">
                                    <MKBox component="form" p={2} method="post">
                                        <MKBox px={3} py={{ xs: 2, sm: 6 }}>
                                            <MKTypography variant="body1" color="text" mb={2}>
                                                Welcome to the IMCHESS Academy Knowledge Center,
                                            </MKTypography>
                                            <MKTypography variant="body2" color="text" mb={2}>
                                                your ultimate resource hub for everything related to chess learning, improvement, and mastery. Whether you're a beginner just starting your chess journey or an experienced player looking to enhance your skills, our Knowledge Center is here to support you every step of the way.
                                            </MKTypography>
                                        </MKBox>
                                        <MKBox pt={0.5} pb={3} px={3}>
                                            <MKTypography variant="h3" color="info" mb={2}>
                                                What You'll Find Here?
                                            </MKTypography>
                                            <Grid container>
                                                <MKTypography variant="h5" color="info" mb={2}>
                                                    1. Chess Lessons and Tutorials
                                                </MKTypography>
                                                <MKTypography variant="body2" color="text" mb={2}>
                                                    Dive into our extensive collection of chess lessons and tutorials covering all aspects of the game. From basic rules and strategies to advanced tactics and endgame techniques, we've got you covered.
                                                </MKTypography>

                                                <MKTypography variant="h5" color="info" mb={2}>
                                                    2. Insightful Articles and Analysis
                                                </MKTypography>
                                                <MKTypography variant="body2" color="text" mb={2}>
                                                    Stay updated with the latest in the world of chess through our insightful articles and game analysis. Explore interesting games, learn from expert commentary, and gain valuable insights to elevate your gameplay.
                                                </MKTypography>

                                                <MKTypography variant="h5" color="info" mb={2}>
                                                    3. Training Materials and Exercises
                                                </MKTypography>
                                                <MKTypography variant="body2" color="text" mb={2}>
                                                    Take your skills to the next level with our carefully curated training materials and exercises. Sharpen your calculation, improve your tactical awareness, and enhance your strategic thinking with our comprehensive resources.
                                                </MKTypography>

                                                <MKTypography variant="h5" color="info" mb={2}>
                                                    4.Video Lessons and Webinars
                                                </MKTypography>
                                                <MKTypography variant="body2" color="text" mb={2}>
                                                    Learn from the masters with our video lessons and webinars conducted by experienced coaches and renowned players. Get valuable tips, tricks, and advice directly from the experts to enhance your understanding of the game.
                                                </MKTypography>

                                                <MKTypography variant="h5" color="info" mb={2}>
                                                    5.Recommended Reading and Resources
                                                </MKTypography>
                                                <MKTypography variant="body2" color="text" mb={2}>
                                                    Discover the best chess books, ebooks, and study materials recommended by our team of experts. Whether you're interested in openings, middlegame strategies, or endgame mastery, we have the resources you need to succeed.
                                                </MKTypography>

                                                <MKTypography variant="h5" color="info" mb={2}>
                                                    6.Player Profiles and Interviews
                                                </MKTypography>
                                                <MKTypography variant="body2" color="text" mb={2}>
                                                    Get inspired by the journeys of accomplished players, coaches, and chess personalities. Learn about their experiences, insights, and tips for success in exclusive interviews and player profiles.
                                                </MKTypography>

                                                <MKTypography variant="h5" color="info" mb={2}>
                                                    7.Chess Events and Tournaments
                                                </MKTypography>
                                                <MKTypography variant="body2" color="text" mb={2}>
                                                    Stay informed about upcoming chess events, tournaments, and competitions happening around the world. Find out how you can participate, compete, and showcase your skills on the global stage.
                                                </MKTypography>

                                                <MKTypography variant="h5" color="info" mb={2}>
                                                    8.Community Forums and Discussions
                                                </MKTypography>
                                                <MKTypography variant="body2" color="text" mb={2}>
                                                    Connect with fellow chess enthusiasts, share your experiences, and engage in discussions on our community forums. Ask questions, seek advice, and interact with like-minded individuals passionate about the game.
                                                </MKTypography>

                                                <MKTypography variant="h5" color="info" mb={2}>
                                                    9.Chess News and Updates
                                                </MKTypography>
                                                <MKTypography variant="body2" color="text" mb={2}>
                                                    Keep up to date with the latest news, updates, and developments in the world of chess. From tournament results to rule changes, we'll keep you informed and in the loop.
                                                </MKTypography>

                                                <MKTypography variant="h5" color="info" mb={2}>
                                                    10.FAQs and Chess Resources
                                                </MKTypography>
                                                <MKTypography variant="body2" color="text" mb={2}>
                                                    Have questions about chess? Check out our FAQ section for answers to common queries. Plus, explore a wealth of external resources, federations, and organizations to further expand your chess knowledge.
                                                </MKTypography>

                                                <MKTypography variant="h4" color="info" mb={2}>
                                                    Join the IMCHESS Academy Community Today!
                                                </MKTypography>
                                                <MKTypography variant="body2" color="text" mb={2}>
                                                    Whether you're a casual player looking to have fun or a serious competitor aiming for mastery, the IMCHESS Academy Knowledge Center is your go-to destination for all things chess. Join our community today, explore our resources, and embark on an exciting journey of chess discovery and improvement. Let's make every move count!
                                                </MKTypography>
                                            </Grid>
                                        </MKBox>
                                    </MKBox>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </MKBox>
            </Card>

            <MKBox pt={6} px={1} mt={6}>
                <DefaultFooter content={footerRoutes} />
            </MKBox>
        </>
    );
}

export default KnowledgeCenter;