// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import { TypeAnimation } from "react-type-animation";
import { Divider } from "@mui/material";
import CubeSwipper from "pages/Common/CubeSwipper/Index";
import ContentSwipper from "pages/Common/ParallaxCarousel/Index";
import bgAchivements from "assets/images/bg-achivements.jpeg";
import hanya1 from "assets/images/studs/hanya1.jpg";
import hanya2 from "assets/images/studs/hanya2.jpg";
import hanya3 from "assets/images/studs/hanya3.jpg";
import hanya4 from "assets/images/studs/hanya4.jpg";
import hanya5 from "assets/images/studs/hanya5.jpg";
import hanya6 from "assets/images/studs/hanya6.jpg";
import hanya7 from "assets/images/studs/hanya7.jpg";
import hanya8 from "assets/images/studs/hanya8.jpg";
import hanya9 from "assets/images/studs/hanya9.jpg";

import shubh1 from "assets/images/studs/shubh1.jpg";
import shubh2 from "assets/images/studs/shubh2.jpg";
import shubh3 from "assets/images/studs/shubh3.jpg";
import shubh4 from "assets/images/studs/shubh4.jpg";
import shubh5 from "assets/images/studs/shubh5.jpg";
import shubh6 from "assets/images/studs/shubh6.jpg";
import shubh7 from "assets/images/studs/shubh7.jpg";
import shubh8 from "assets/images/studs/shubh8.jpg";
import shubh9 from "assets/images/studs/shubh9.jpg";

import kanav1 from "assets/images/studs/kanav1.jpg";
import kanav2 from "assets/images/studs/kanav2.jpg";
import kanav3 from "assets/images/studs/kanav3.jpg";
import kanav4 from "assets/images/studs/kanav4.jpg";
import kanav5 from "assets/images/studs/kanav5.jpg";
import kanav6 from "assets/images/studs/kanav6.jpg";
import kanav7 from "assets/images/studs/kanav7.jpg";
import kanav8 from "assets/images/studs/kanav8.jpg";
import kanav9 from "assets/images/studs/kanav9.jpg";

import tanay1 from "assets/images/studs/tanay1.jpg";
import tanay2 from "assets/images/studs/tanay2.jpg";
import tanay3 from "assets/images/studs/tanay3.jpg";

import charvi1 from "assets/images/studs/charvi1.jpg";

import hari1 from "assets/images/studs/hari1.jpg";
import hari2 from "assets/images/studs/hari2.jpg";
import hari3 from "assets/images/studs/hari3.jpg";

import aarsh1 from "assets/images/studs/aarsh1.jpg";
import aarsh2 from "assets/images/studs/aarsh2.jpg";

function SuccessStories() {
    let students = [
        {
            id: "1",
            title: "August 2022 To Till Date",
            name: "Hanya Shah",
            images: [hanya1, hanya2, hanya3, hanya4, hanya5, hanya6, hanya7, hanya8, hanya9],
            description: "Journey 1149 to 1888",
            current: "",
            story: "Hanya Shah, 12, began her coaching with International Master Manush Shah in July 2022. Starting with an Elo rating of 1149, she has now reached 1888. Along the way, she won the Western Asian U-10 Chess Championship, became a multiple-time state champion, and succeeded in national-level tournaments. Hanya’s growth reflects her talent and dedication, marking her as a promising chess talent.",
            slider: true
        },
        {
            id: "2",
            title: "August 2022 to Till Date",
            name: "Shubh Atha",
            images: [shubh1, shubh2, shubh3, shubh4, shubh5, shubh6, shubh7, shubh8, shubh9],
            description: "",
            story: "At just 11 years old, Shubh Atha has experienced an incredible rise in his chess career since joining coaching with International Master Manush Shah in July 2022. Starting with a FIDE rating of 1006, he has skyrocketed to an impressive 1875. Shubh’s tournament performances have been remarkable, consistently defeating higher-rated players and securing top prizes in Open category tournaments. His rapid ascent and fearless play showcase his determination and talent, making him a formidable presence in the chess world.",
            slider: true
        },
        {
            id: "3",
            title: "August 2022 to Till Date",
            name: "Tanay Sanghani",
            images: [tanay1, tanay2, tanay3],
            description: "",
            story: "Tanay Sanghani, a 10-year-old from South Carolina, United States, has been excelling in chess while maintaining strong academic performance. Competing in numerous Scholastic Tournaments, he has consistently showcased his talent and dedication, significantly boosting his USCF rating to an impressive 1935. Tanay's ability to dominate tournaments while excelling academically highlights his remarkable focus and potential, making him a well-rounded and promising young talent.",
            slider: true
        },
        {
            id: "4",
            title: "August 2022 to Till Date",
            name: "Kanav Shah",
            images: [kanav1, kanav2, kanav3, kanav4, kanav5, kanav6, kanav7, kanav8, kanav9],
            story: "Kanav Shah, a 10-year-old from the United States, has shown remarkable dedication to chess. Competing consistently in tournaments, he has performed at a high level, winning numerous Scholastic Tournaments and significantly improving his ratings. With a FIDE rating of 1717 and a USCF rating of 1500, Kanav's commitment and progress at such a young age are truly impressive, making him a player to watch in the chess community.",
            description: "",
            slider: true
        },
        {
            id: "5",
            title: "August 2022 to Till Date",
            name: "Chaarvi Chaudhary",
            images: [charvi1],
            story: "Chaarvi Choudhary, a talented 15-year-old from Assam, India, has been training with International Master Manush Shah for the past six months. During this time, she has made significant strides in her chess career, winning multiple district and state championships in Assam. With a FIDE rating of 1715, Chaarvi's achievements reflect her dedication and hard work in the sport. Her commitment to improving her skills and competing at a high level positions her as an inspiring figure in the chess community, showcasing the potential for growth and success in young players.",
            description: "",
            slider: false
        },
        {
            id: "6",
            title: "August 2022 to Till Date",
            name: "Hari Vardan S",
            images: [hari1, hari2, hari3],
            story: "Hari Vardan S, a 12-year-old from Tamil Nadu, India, has made impressive strides in his chess journey in just three months of training with International Master Manush Shah. He has already secured victories in numerous state and district-level tournaments, showcasing his talent and determination. Hari has also participated in open-rated tournaments in both Abu Dhabi and India, where he has significantly improved his rating to 1805 in a remarkably short time. His dedication to the game and rapid progress position him as a rising star in the chess community.",
            description: "",
            slider: true
        },
        {
            id: "7",
            title: "August 2022 to Till Date",
            name: "Aarsh Bagda",
            images: [aarsh1, aarsh2],
            description: "",
            slider: true
        },
    ]
    return (
        <>
            <DefaultNavbar
                routes={routes}
                transparent
                light
            />
            <MKBox
                minHeight="40vh"
                width="100%"
                sx={{
                    backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${bgAchivements})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <Container>
                    <Grid
                        container
                        item
                        xs={12}
                        lg={8}
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        sx={{ mx: "auto", textAlign: "center" }}
                    >
                        <MKTypography
                            variant="h3"
                            color="white"
                            sx={({ breakpoints, typography: { size } }) => ({
                                [breakpoints.down("md")]: {
                                    fontSize: size["3xl"],
                                },
                            })}
                        >
                            <TypeAnimation
                                preRenderFirstString={true}
                                sequence={[
                                    500,
                                    `Step into the winner's circle with our array of sports achievements`, // initially rendered starting point
                                    1000,
                                    `Step into the winner's circle with our array of sports achievers`,
                                    1000,
                                    `Step into the winner's circle with our array of sports achievements`,
                                    1000,
                                    `Step into the winner's circle with our array of sports achievers`,
                                    500,
                                ]}
                                speed={50}
                                // style={{ fontSize: '2em' }}
                                repeat={Infinity}
                            />
                        </MKTypography>

                        <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
                            Where passion, perseverance, and prowess collide for unforgettable victories!
                        </MKTypography>
                    </Grid>
                </Container>
            </MKBox>
            <Card
                sx={{
                    p: 2,
                    mx: { xs: 2, lg: 3 },
                    mt: -8,
                    mb: 4,
                    boxShadow: ({ boxShadows: { xxl } }) => xxl,
                    // backgroundImage: `url(${bgImage})`,
                    // backgroundColor: '#000'
                }}
            >
                <Container>
                    <Divider
                        orientation="vertical"
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "-4px",
                            transform: "translateY(-45%)",
                            height: "90%",
                            backgroundColor: '#86c33a'
                        }}
                    />


                    {/* --------------------- */}
                    {students?.map((student) => {
                        return student?.slider ?
                            <>
                                <MKBox component="section" pt={6} my={6}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} md={5}>
                                            <div className="container mx-auto mt-10">
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={10} className="relative z-10" sx={{ marginTop: '-50px' }}>
                                                        <CubeSwipper images={student?.images} />
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Grid>
                                        <Grid item md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
                                            <MKTypography variant="body1" color="text" mb={3}>
                                                {student?.title}
                                            </MKTypography>
                                            <MKTypography variant="h4">{student?.name}</MKTypography>
                                            <MKTypography variant="body2" color="text" mb={3}>
                                                {student?.description}
                                            </MKTypography>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    {student?.story}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </MKBox>
                                <Divider sx={{ backgroundColor: '#86c33a' }} />
                            </> :
                            <>
                                <MKBox component="section" pt={6} my={6}>
                                    <Grid container alignItems="center">
                                        <Grid item md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
                                            <MKTypography variant="body1" color="text" mb={3}>
                                                {student?.title}
                                            </MKTypography>
                                            <MKTypography variant="h4">{student?.name}</MKTypography>
                                            <MKTypography variant="body2" color="text" mb={3}>
                                                {student?.description}
                                            </MKTypography>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    {student?.story}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
                                            <div className="container mx-auto mt-10">
                                                <Grid container spacing={2} sx={{ backgroundColor: '#86c33a', borderRadius: '2%' }}>
                                                    <Grid item xs={12} md={10} className="relative z-10" sx={{ marginTop: '-50px' }}>
                                                        <MKBox component="img" src={student?.images?.[0]} alt="picture" width="50%" height="95%" borderRadius={'1%'} />
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </MKBox>
                                <Divider sx={{ backgroundColor: '#86c33a' }} />
                            </>
                    }
                    )}
                    {/* ---------------- */}
                    <ContentSwipper />
                    <Divider sx={{ backgroundColor: '#86c33a' }} />
                </Container>
            </Card>
            <MKBox pt={6} px={1} mt={6}>
                <DefaultFooter content={footerRoutes} />
            </MKBox>
        </>
    );
}

export default SuccessStories;