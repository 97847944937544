export const currentCourseData = [
    {
        image: "https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
        title: "7 Days online workshop by International Master : Unlock your potential with certification",
        description: "7-day online workshop for rated chess players, May 18th-24th, 2024. Enhance your skills, limited slots available!",
        route: "/workshops",
        disabled: true
    },
    {
        image: "https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
        title: "7 Days online workshop by International Master : Unlock your potential with certification",
        description: "7-day online workshop for rated chess players, May 18th-24th, 2024. Enhance your skills, limited slots available!",
        route: "/workshops",
        disabled: false
    },
    {
        image: "https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
        title: "7 Days online workshop by International Master : Unlock your potential with certification",
        description: "7-day online workshop for rated chess players, May 18th-24th, 2024. Enhance your skills, limited slots available!",
        route: "/workshops",
        disabled: false
    },
    {
        image: "https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
        title: "7 Days online workshop by International Master : Unlock your potential with certification",
        description: "7-day online workshop for rated chess players, May 18th-24th, 2024. Enhance your skills, limited slots available!",
        route: "/workshops",
        disabled: false
    }
]