import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

import routes from "routes";
import footerRoutes from "footer.routes";

import { TypeAnimation } from "react-type-animation";
import PhotoAlbum from "react-photo-album";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

import bgGallery from "assets/images/bg-gallery.jpeg";

import shubh1 from "assets/images/studs/shubh1.jpg";
import hanya1 from "assets/images/studs/hanya1.jpg";
import hanya6 from "assets/images/studs/hanya6.jpg";
import hari1 from "assets/images/studs/hari1.jpg";
import hari4 from "assets/images/studs/hari4.jpg";
import hanya9 from "assets/images/studs/hanya9.jpg";
import hanya19 from "assets/images/studs/hanya19.jpg";
import hanya22 from "assets/images/studs/hanya22.jpg";
import kanav1 from "assets/images/studs/kanav1.jpg";
import kanav3 from "assets/images/studs/hanya6.jpg";
import kanav5 from "assets/images/studs/kanav5.jpg";
import kanav10 from "assets/images/studs/kanav10.jpg";
import kanav11 from "assets/images/studs/kanav11.jpg";
import shubh10 from "assets/images/studs/shubh10.jpg";
import hanya12 from "assets/images/studs/hanya12.jpg";
import shubh11 from "assets/images/studs/shubh11.jpg";
import shubh13 from "assets/images/studs/shubh13.jpg";
import shubh16 from "assets/images/studs/shubh16.jpg";
import shubh18 from "assets/images/studs/shubh18.jpg";
import shubh19 from "assets/images/studs/shubh19.jpg";
import tanay1 from "assets/images/studs/tanay1.jpg";
import tanay2 from "assets/images/studs/tanay2.jpg";
import aarsh1 from "assets/images/studs/aarsh1.jpg";
import charvi1 from "assets/images/studs/charvi1.jpg";

import kanav6 from "assets/images/studs/kanav6.jpg";

function Gallery() {
    const { pathname } = useLocation();
    const imcPhotos = [
        {
            src: shubh1,
            width: 4,
            height: 3
        },
        {
            src: hanya1,
            width: 3,
            height: 3
        },
        {
            src: hanya6,
            width: 3,
            height: 5
        },
        {
            src: aarsh1,
            width: 4,
            height: 4
        },
        {
            src: shubh10,
            width: 5,
            height: 5
        },
        {
            src: kanav1,
            width: 4,
            height: 3
        },
        {
            src: hari1,
            width: 3,
            height: 4
        },
        {
            src: charvi1,
            width: 4,
            height: 7
        },
        {
            src: hari4,
            width: 4,
            height: 3
        },
        {
            src: hanya9,
            width: 3,
            height: 4
        },
        {
            src: hanya19,
            width: 4,
            height: 3
        },
        {
            src: hanya22,
            width: 3,
            height: 4
        },
        {
            src: kanav3,
            width: 4,
            height: 3
        },
        {
            src:shubh11,
            width: 4927,
            height: 1000
        },
        {
            src: kanav11,
            width: 4,
            height: 3
        },
        {
            src: shubh13,
            width: 4,
            height: 3
        },
        {
            src: kanav5,
            width: 4,
            height: 3
        },
        {
            src: hanya12,
            width: 4,
            height: 4
        },
        {
            src: shubh16,
            width: 4,
            height: 3
        },
        {
            src: kanav10,
            width: 4,
            height: 3
        },
        {
            src: tanay1,
            width: 4,
            height: 3
        },
        {
            src: shubh19,
            width: 4,
            height: 3
        },
        {
            src: tanay2,
            width: 4,
            height: 3
        },
        {
            src: shubh18,
            width: 4,
            height: 3
        }
    ];
    const coachPhotos = [
        {
            src: kanav6,
            width: 4,
            height: 7
        },
        {
            src: "https://source.unsplash.com/Dm-qxdynoEc/800x799",
            width: 1,
            height: 1
        },
        {
            src: "https://source.unsplash.com/qDkso9nvCg0/600x799",
            width: 3,
            height: 4
        },
        {
            src: "https://source.unsplash.com/iecJiKe_RNg/600x799",
            width: 3,
            height: 4
        },
        {
            src: "https://source.unsplash.com/PpOHJezOalU/800x599",
            width: 4,
            height: 3
        },
        {
            src: "https://source.unsplash.com/I1ASdgphUH4/800x599",
            width: 4,
            height: 3
        },
        {
            src: "https://source.unsplash.com/XiDA78wAZVw/600x799",
            width: 3,
            height: 4
        },
        {
            src: "https://source.unsplash.com/x8xJpClTvR0/800x599",
            width: 4,
            height: 3
        },
        {
            src: "https://source.unsplash.com/A-fubu9QJxE/800x533",
            width: 4,
            height: 3
        },
        {
            src: "https://source.unsplash.com/5P91SF0zNsI/740x494",
            width: 4,
            height: 3
        }
    ];
    const [galleryPhotos, setGalleryPhotos] = useState([]);
    useEffect(() => {
        setGalleryPhotos(pathname === '/gallery/imc' ? imcPhotos : coachPhotos);
    }, [pathname])
    return (
        <>
            <DefaultNavbar
                routes={routes}
                transparent
                light
            />
            <MKBox
                minHeight="40vh"
                width="100%"
                sx={{
                    backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${bgGallery})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <Container>
                    <Grid
                        container
                        item
                        xs={12}
                        lg={8}
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        sx={{ mx: "auto", textAlign: "center" }}
                    >
                        {pathname === '/gallery/imc' ?
                            <MKTypography
                                variant="h1"
                                color="white"
                                sx={({ breakpoints, typography: { size } }) => ({
                                    [breakpoints.down("md")]: {
                                        fontSize: size["3xl"],
                                    },
                                })}
                            >
                                <TypeAnimation
                                    preRenderFirstString={true}
                                    sequence={[
                                        500,
                                        'IMCHESS GALLERY', // initially rendered starting point
                                        1000,
                                        'IMCHESS WORLD',
                                        1000,
                                        'IMCHESS GALLERY',
                                        1000,
                                        'IMCHESS WORLD',
                                        500,
                                    ]}
                                    speed={50}
                                    // style={{ fontSize: '2em' }}
                                    repeat={Infinity}
                                />
                            </MKTypography> :
                            <MKTypography
                                variant="h1"
                                color="white"
                                sx={({ breakpoints, typography: { size } }) => ({
                                    [breakpoints.down("md")]: {
                                        fontSize: size["3xl"],
                                    },
                                })}
                            >
                                <TypeAnimation
                                    preRenderFirstString={true}
                                    sequence={[
                                        500,
                                        'TEAM GALLERY', // initially rendered starting point
                                        1000,
                                        'COACH WORLD',
                                        1000,
                                        'IMCHESS GALLERY',
                                        1000,
                                        'CHESS MEMORIES',
                                        500,
                                    ]}
                                    speed={50}
                                    // style={{ fontSize: '2em' }}
                                    repeat={Infinity}
                                />
                            </MKTypography>
                        }
                        {pathname === '/gallery/imc' ?
                            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
                                Each image tells a story of dedication, growth, and success.
                            </MKTypography> :
                            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
                                Step into the world of elite coaching through our IM Coach where expertise knows no borders, only excellence.
                            </MKTypography>}
                    </Grid>
                </Container>
            </MKBox>
            <Card
                sx={{
                    p: 2,
                    mx: { xs: 2, lg: 3 },
                    mt: -8,
                    mb: 4,
                    boxShadow: ({ boxShadows: { xxl } }) => xxl,
                }}
            >
                <PhotoAlbum layout="columns" photos={galleryPhotos} />
            </Card>
            <MKBox pt={6} px={1} mt={6}>
                <DefaultFooter content={footerRoutes} />
            </MKBox>
        </>
    );
}

export default Gallery;