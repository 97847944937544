export const online = [
    {
        image: "https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
        title: "Beginner 1",
        description: "Website visitors today demand a frictionless user expericence — especially when using search. Because of the hight standards.",
        featurePoints: ["abc", "def", "gehijk lmnopqrs tuv wxy z"],
        duration: 2,
        frequency: "",
        noOfSessions: 10,
        ageCategory: "Above 5 years",
        days: ["All", "Beginner"]
    },
    {
        image: "https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
        title: "Beginner 2",
        description: "Website visitors today demand a frictionless user expericence — especially when using search. Because of the hight standards.",
        featurePoints: ["abc", "def", "gehijk lmnopqrs tuv wxy z"],
        duration: 2,
        frequency: "",
        noOfSessions: 10,
        ageCategory: "Above 5 years",
        days: ["All", "Beginner"]
    },
    {
        image: "https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
        title: "Intemediate 1",
        description: "Website visitors today demand a frictionless user expericence — especially when using search. Because of the hight standards.",
        featurePoints: ["abc", "def", "gehijk lmnopqrs tuv wxy z"],
        duration: 2,
        frequency: "",
        noOfSessions: 10,
        ageCategory: "Above 5 years",
        days: ["All", "Intemediate"]
    },
    {
        image: "https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
        title: "Intemediate 2",
        description: "Website visitors today demand a frictionless user expericence — especially when using search. Because of the hight standards.",
        featurePoints: ["abc", "def", "gehijk lmnopqrs tuv wxy z"],
        duration: 2,
        frequency: "",
        noOfSessions: 10,
        ageCategory: "Above 5 years",
        days: ["All", "Intemediate"]
    },
    {
        image: "https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
        title: "Advance 1",
        description: "Website visitors today demand a frictionless user expericence — especially when using search. Because of the hight standards.",
        featurePoints: ["abc", "def", "gehijk lmnopqrs tuv wxy z"],
        duration: 2,
        frequency: "",
        noOfSessions: 10,
        ageCategory: "Above 5 years",
        days: ["All", "Advance"]
    },
    {
        image: "https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
        title: "Advance 2",
        description: "Website visitors today demand a frictionless user expericence — especially when using search. Because of the hight standards.",
        featurePoints: ["abc", "def", "gehijk lmnopqrs tuv wxy z"],
        duration: 2,
        frequency: "",
        noOfSessions: 10,
        ageCategory: "Above 5 years",
        days: ["All", "Advance"]
    },
    {
        image: "https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
        title: "Individual",
        description: "Website visitors today demand a frictionless user expericence — especially when using search. Because of the hight standards.",
        featurePoints: [],
        duration: 2,
        frequency: "",
        noOfSessions: 10,
        ageCategory: "Above 5 years",
        days: ["All", "Individual"]
    },
];
export const offline = [

];