import React, { useState } from 'react';
import { Movetext, NavigationBoard } from 'kokopu-react';

import { useEffect, useRef, useCallback } from 'react';
import _ from 'lodash'; // Assuming lodash is available for debouncing
import { Grid } from '@mui/material';
import MKTypography from 'components/MKTypography';

const GameViewer = ({ game, oponent1, oponent2, quote }) => {
    const [fen, setFen] = useState(getStartingFEN());
    const [nodeId, setNodeId] = useState('end');

    // Function to get starting FEN notation
    function getStartingFEN() {
        return 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1';
    }
    // Function to handle move selection
    const handleMoveSelected = (currentNodeId) => {
        setNodeId(currentNodeId);
        setFen(currentNodeId);
    };
    return (
        <Grid container alignItems="center" mt={6} mb={6}>
            <Grid item xs={12} md={5}>
                <div className="container mx-auto mt-10">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={10} className="relative z-10" sx={{ marginTop: '-50px' }}>
                            <ViewBoard game={game} nodeId={nodeId} setNodeId={setNodeId} />
                        </Grid>
                    </Grid>
                </div>
            </Grid>
            <Grid item md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
                <MKTypography variant="h4">{quote}</MKTypography>
                <MKTypography variant="h5" color="text">
                    {oponent1}
                </MKTypography>
                <MKTypography variant="h5" color="text" mb={3}>
                    {oponent2}
                </MKTypography>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Movetext
                            game={game}
                            pieceSymbols="figurines"
                            diagramVisible={true}
                            diagramOptions={{ flipped: true }}
                            selection={nodeId}
                            interactionMode="selectMove"
                            onMoveSelected={(currentNodeId) => handleMoveSelected(currentNodeId)}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default GameViewer;


export const ViewBoard = ({ game, nodeId, setNodeId }) => {
    const nodeIdRef = useRef(nodeId);
    const isInitialMount = useRef(true);

    const updateParallaxEffect = useCallback((newNodeId) => {
        // Implement your parallax control logic here
        // For example, you could update the parallax instance with the new nodeId
    }, []);

    const debouncedUpdateParallaxEffect = useCallback(
        _.debounce(updateParallaxEffect, 5000), // Adjust the debounce delay as needed
        [updateParallaxEffect]
    );

    const handleNodeIdChanged = useCallback((newNodeId) => {
        if (nodeIdRef.current !== newNodeId) {
            nodeIdRef.current = newNodeId;
            setNodeId(newNodeId);
            debouncedUpdateParallaxEffect(newNodeId);
        }
    }, [debouncedUpdateParallaxEffect]);

    // useEffect to set nodeId state on initial mount
    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            setNodeId(nodeIdRef.current);
        }
    }, []);

    return (
        <NavigationBoard
            game={game} // Assuming `game` is defined in your scope
            animated
            nodeId={nodeId} // Pass the current nodeId state
            onNodeIdChanged={handleNodeIdChanged} // Pass the handler function
            playButtonVisible
        />
    );
};