import Container from "@mui/material/Container";

// React components
import MKBox from "components/MKBox";
import FeaturesOne from "layouts/sections/page-sections/featuers/components/FeaturesOne";

function ChessBenefits() {
  const bgImage = "https://i.pinimg.com/564x/35/ad/6e/35ad6ec49f7f64dd27c1b2e7da4f8a8b.jpg";

  return (
    <MKBox
      display="flex"
      alignItems="center"
      borderRadius="xl"
      my={2}
      py={12}
      sx={{
        backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
          `${linearGradient(
            rgba(gradients.info.main, 0.9),
            rgba(gradients.success.state, 0.9)
          )}, url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Container>
        <FeaturesOne
          color="white"
          title="BENEFITS OF CHESS"
          description="From colors, cards, typography to complex elements, you will find the full
                documentation. Play with the utility classes and you will create unlimited combinations
                for our components."
          button=""
          point1="It becomes harder for us to give others a hand. <br/> We get our heart broken by people we love."
          point2="It becomes harder for us to give others a hand. <br/> We get our heart broken by people we love."
          point3="It becomes harder for us to give others a hand. <br/> We get our heart broken by people we love."
        />
      </Container>
    </MKBox>
  );
}

export default ChessBenefits;
