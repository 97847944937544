import Grid from "@mui/material/Grid";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import { Container } from "@mui/material";

function TeamImc() {
    return (
        <>
            <MKBox component="section" pt={6} my={6}>
                <Grid container alignItems="center">
                    {/* <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
                        <MKBox position="relative">
                            <MKBox component="img" src={'https://st.depositphotos.com/1345889/1478/i/450/depositphotos_14784249-stock-photo-friendly-pensive-brunette-beauty.jpg'} alt="picture" width="100%" borderRadius={'1%'} />
                        </MKBox>
                    </Grid> */}
                    <Grid item xs={12} md={5} sx={{ ml: "15px" }}>
                        <div className="container mx-auto mt-10" >
                            <Grid container spacing={2} sx={{ backgroundColor: 'pink', borderRadius: '15%' }}>
                                <Grid item xs={12} md={10} className="relative z-10" sx={{ marginTop: '-50px' }}>
                                    <MKBox component="img" src={'https://st.depositphotos.com/1345889/1478/i/450/depositphotos_14784249-stock-photo-friendly-pensive-brunette-beauty.jpg'} alt="picture" width="100%" borderRadius={'1%'} />
                                    {/* <Typography variant="h4" color="textSecondary" textAlign={'center'}>
                                        Title Name SurName
                                    </Typography> */}
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
                        <MKTypography variant="h4">Jimita Gandhi</MKTypography>
                        <MKTypography variant="body2" color="text" mb={3}>
                            Co-Founder and Managing Director
                        </MKTypography>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <MKTypography variant="h6" color="text">
                                    Jimita Gandhi as a Co-founder,
                                </MKTypography>
                                <MKTypography variant="body2" color="success">
                                    She bringing over 3 years of continuous IT experience coupled with a flair for creative ideas and strategies.
                                </MKTypography>
                                <MKTypography variant="body2" color="success">
                                    Her fusion of technical expertise and innovative thinking has propelled IMCHESS Academy to new heights in the realm of chess education.
                                </MKTypography>
                            </Grid>
                            <Grid item xs={12}>
                                <MKTypography variant="h6" color="text">
                                    Jimita Gandhi as a Managing Director,
                                </MKTypography>
                                <MKTypography variant="body2" color="success">
                                    Her forward-thinking approach infuses every aspect of the academy's operations, ensuring a seamless blend of technology and creativity.
                                </MKTypography>
                                <MKTypography variant="body2" color="success">
                                    Under her guidance, IMCHESS Academy continues to revolutionize the way chess is taught and learned, inspiring students to unleash their full potential on the board.
                                </MKTypography>
                                <MKTypography variant="h6" color="text">
                                    A Leadership : Guiding Success Through Vision
                                </MKTypography>
                                <MKTypography variant="body2" color="success">
                                    With exceptional leadership, management, and decision-making skills, she steers strategic planning and risk management with precision.
                                </MKTypography>
                                <MKTypography variant="body2" color="success">
                                    Her guidance ensures alignment with our mission, fostering excellence and sustainable growth.
                                </MKTypography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </MKBox>
            <MKBox component="section" pt={6} my={6}>
                <Grid container alignItems="center">
                    <Grid item md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
                        <MKTypography variant="h4">International Master Manush Shah</MKTypography>
                        <MKTypography variant="body2" color="text" mb={3}>
                            Co-Founder and Chief Coach
                        </MKTypography>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <MKTypography variant="h6" color="text">
                                    International Master Manush Shah, Chief Coach at IMCHESS Academy.
                                </MKTypography>
                                <MKTypography variant="body2" color="success">
                                    With over 2 decades of experience in chess, Manush has trained over 400+ students, secured 150+ trophies, and competed in tournaments across 24+ countries.
                                    His extensive international exposure enriches the training experience for aspiring players at IMCHESS Academy.
                                </MKTypography>
                            </Grid>
                            <Grid item xs={12}>
                                <MKTypography variant="h6" color="text">
                                    Manush Shah as a Co-founder,
                                </MKTypography>
                                <MKTypography variant="body2" color="success">
                                    His vision extends beyond coaching.
                                    His aims to foster a new generation of chess champions through personalized mentorship.
                                </MKTypography>
                                <MKTypography variant="body2" color="success">
                                    His leadership drives IMCHESS Academy as a beacon of excellence, offering enthusiasts a transformative journey in chess.
                                </MKTypography>
                            </Grid>
                            <Grid item xs={12}>
                                <MKTypography variant="h6" color="text">
                                    A Legacy of Excellence:
                                </MKTypography>
                                <MKTypography variant="body2" color="success">
                                    Manaush's journey in the world of chess began at a young age, fueled by a deep fascination with the game's complexity and strategic depth.
                                    Over the years, he honed his skills through dedication, hard work, and a relentless pursuit of excellence, achieving remarkable success both on and off the board.
                                </MKTypography>
                                <MKTypography variant="h6" color="text">
                                    A Commitment to Education:
                                </MKTypography>
                                <MKTypography variant="body2" color="success">
                                    Driven by a desire to share his passion for chess and empower others to reach their full potential,
                                    Manaush co-founded IMCHESS Academy with the vision of providing top-quality chess education to enthusiasts of all ages and skill levels.
                                    As Chief Coach, he brings his unique blend of expertise, enthusiasm, and personalized instruction to every student, inspiring them to excel and achieve their chess goals.
                                </MKTypography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
                        <div className="container mx-auto mt-10">
                            <Grid container spacing={2} sx={{ backgroundColor: 'yellow', borderRadius: '15%' }}>
                                <Grid item xs={12} md={10} className="relative z-10" sx={{ marginTop: '-50px' }}>
                                    <MKBox component="img" src={'https://i.pinimg.com/originals/a2/f4/92/a2f4921b5992b535f9cd21744b25fe6e.jpg'} alt="picture" width="100%" borderRadius={'1%'} />
                                    {/* <Typography variant="h4" color="textSecondary" textAlign={'center'}>
                                        Title Name SurName
                                    </Typography> */}
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </MKBox>
            <MKBox component="section" pt={8}>
                <MKBox
                    variant="gradient"
                    bgColor="info"
                    position="relative"
                    borderRadius="xl"
                    sx={{ overflow: "hidden" }}
                >
                    <Container>
                        <Grid container justifyContent="center">
                            <Grid item xs={12} p={6} textAlign="center">
                                <MKTypography variant="h4" color="dark">
                                    Choose Your Future Wisely!
                                </MKTypography>
                                <MKTypography variant="h6" color="white">
                                    At IMCHESS Academy, our dedication to chess education is unwavering.
                                </MKTypography>
                                <MKTypography variant="h6" color="white">
                                    We believe that chess is not just a game but a powerful tool for intellectual growth and personal development.
                                </MKTypography>
                                <MKTypography variant="h6" color="white">
                                    With a passionate team, we strive to provide the highest quality of education to enthusiasts of all ages and skill levels.
                                    Through personalized instruction, innovative teaching methods, and a supportive learning environment,
                                    we empower students to unlock their full potential in the game of chess.
                                </MKTypography>
                                <MKTypography variant="h6" color="white">
                                    Join us to excellence and embark on a transformative journey of learning and growth at IMCHESS Academy.
                                </MKTypography>
                            </Grid>
                        </Grid>
                    </Container>
                </MKBox>
            </MKBox>
            {/* <MKBox component="section" pt={6} my={6}>
                <Grid container alignItems="center" sx={{ pb: 4, backgroundColor: '#F2F2F2' }}>
                    <Grid item xs={12} md={5} sx={{ ml: "auto", mt: -6 }}>
                        <MKBox position="relative">
                            <MKBox component="img" src={'https://t3.ftcdn.net/jpg/01/42/01/84/360_F_142018449_yR0avsaJqbIx8NA47sINMoaxdtn1sPzh.jpg'} alt="picture" width="100%" borderRadius='1%' style={{ marginTop: '-20px' }} />
                        </MKBox>
                    </Grid>
                    <Grid item md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
                        <MKTypography variant="h4">Be the first to join and become a chess player</MKTypography>
                        <MKTypography variant="body2" color="text" mb={3}>
                            Your luck may not be in the chess world, but eventually, a chess
                            will be in your luck. Your luck may not be in the chess world, but eventually, a chess
                            will be in your luck.
                        </MKTypography>
                        <Grid container spacing={1}>
                            <Grid item xs={10}>
                                cydshj kskcydshj kskcydshj kskcydshj kskcydshj kskcydshj ksk kskcydshj kskcydshj kskcydshj kskcydshj kskcydshj ksk
                                kskcydshj kskcydshj kskcydshj kskcydshj kskcydshj kskkskcydshj kskcydshj kskcydshj kskcydshj kskcydshj ksk
                                kskcydshj kskcydshj kskcydshj kskcydshj kskcydshj kskkskcydshj kskcydshj kskcydshj kskcydshj kskcydshj ksk
                                kskcydshj kskcydshj kskcydshj kskcydshj kskcydshj kskkskcydshj kskcydshj kskcydshj kskcydshj kskcydshj ksk
                                kskcydshj kskcydshj kskcydshj kskcydshj kskcydshj ksk.
                                kskcydshj kskcydshj kskcydshj kskcydshj kskcydshj ksk.
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </MKBox >
            <MKBox component="section" pt={6} my={6}>

                <Grid container>
                    <Grid item md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Grid item xs={12} lg={10} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
                                    <Stack>
                                        <MKBox display="flex" alignItems="center" p={2}>
                                            <MKBox
                                                width="3rem"
                                                height="3rem"
                                                variant="gradient"
                                                bgColor="info"
                                                color="white"
                                                coloredShadow="info"
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                                borderRadius="xl"
                                            >
                                                <Icon fontSize="small">mediation</Icon>
                                            </MKBox>
                                            <MKTypography variant="body2" color={'text'} pl={2} >
                                                <p dangerouslySetInnerHTML={{ __html: "hsdcbsdc hsdcbsdc hsdcbsdc hsdcbsdc hsdcbsdc hsdcbsdc hsdcbsdc hsdcbsdc hsdcbsdc" }} />
                                            </MKTypography>
                                        </MKBox>
                                        <MKBox display="flex" alignItems="center" p={2}>
                                            <MKBox
                                                width="3rem"
                                                height="3rem"
                                                variant="gradient"
                                                bgColor="info"
                                                color="white"
                                                coloredShadow="info"
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                                borderRadius="xl"
                                            >
                                                <Icon fontSize="small">settings_overscan</Icon>
                                            </MKBox>
                                            <MKTypography variant="body2" color={'text'} pl={2}>
                                                <p dangerouslySetInnerHTML={{ __html: 'dccnjm,  adkjsncajxxdn akjdxdnm ajxxdn akjdxdnm' }} />
                                            </MKTypography>
                                        </MKBox>
                                        <MKBox
                                            display="flex" alignItems="center" p={2}>
                                            <MKBox
                                                width="3rem"
                                                height="3rem"
                                                variant="gradient"
                                                bgColor="info"
                                                color="white"
                                                coloredShadow="info"
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                                borderRadius="xl"
                                            >
                                                <Icon fontSize="small">token</Icon>
                                            </MKBox>
                                            <MKTypography variant="body2" color={'text'} pl={2}>
                                                <p dangerouslySetInnerHTML={{ __html: 'dchsbc  ajxxdn akjdxdnm ajxxdn akjdxdnm' }} />
                                            </MKTypography>
                                        </MKBox>
                                        <MKBox
                                            display="flex" alignItems="center" p={2}>
                                            <MKBox
                                                width="3rem"
                                                height="3rem"
                                                variant="gradient"
                                                bgColor="info"
                                                color="white"
                                                coloredShadow="info"
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                                borderRadius="xl"
                                            >
                                                <Icon fontSize="small">token</Icon>
                                            </MKBox>
                                            <MKTypography variant="body2" color={'text'} pl={2}>
                                                <p dangerouslySetInnerHTML={{ __html: 'dchsbc  ajxxdn akjdxajxxdn akjdxdnmdnm' }} />
                                            </MKTypography>
                                        </MKBox>
                                        <MKBox
                                            display="flex" alignItems="center" p={2}>
                                            <MKBox
                                                width="3rem"
                                                height="3rem"
                                                variant="gradient"
                                                bgColor="info"
                                                color="white"
                                                coloredShadow="info"
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                                borderRadius="xl"
                                            >
                                                <Icon fontSize="small">token</Icon>
                                            </MKBox>
                                            <MKTypography variant="body2" color={'text'} pl={2}>
                                                <p dangerouslySetInnerHTML={{ __html: 'dchsbc  ajxxdn akjdxdnajxxdn akjdxdnm ajxxdn akjdxdnmm' }} />
                                            </MKTypography>
                                        </MKBox>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={5} sx={{ ml: "auto", mt: -6 }}>
                        <MKBox position="relative">
                            <MKBox component="img" src={'https://media.gettyimages.com/id/1351416161/photo/female-teachers-teaching-students-in-library-at-school.jpg?s=612x612&w=gi&k=20&c=4DfIobmZOXpkgSBah5_dan469UkjpTDkm_q8JYeVDDM='} alt="picture" width="100%" borderRadius='1%' style={{ marginTop: '-20px' }} />
                        </MKBox>
                    </Grid>
                </Grid>

            </MKBox>
            <MKBox component="section" pt={6} my={6}>
                <Grid container alignItems="center" sx={{ pt: 4, backgroundColor: '#F2F2F2' }}>
                    <Grid item xs={12} md={5} sx={{ ml: "auto", mt: -6 }}>
                        <MKBox position="relative" sx={{ overflow: 'visible' }}>
                            <MKBox
                                component="img"
                                src={'https://img.freepik.com/free-photo/indoor-shot-beautiful-happy-african-american-woman-smiling-cheerfully-keeping-her-arms-folded-relaxing-indoors-after-morning-lectures-university_273609-1270.jpg'}
                                alt="picture"
                                width="100%"
                                borderRadius='1%'
                                sx={{ marginTop: '-20px' }}
                            />
                        </MKBox>
                    </Grid>
                    <Grid item md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
                        <MKTypography variant="h4">Be the first to join and become a chess player</MKTypography>
                        <MKTypography variant="body2" color="text" mb={3}>
                            Your luck may not be in the chess world, but eventually, a chess
                            will be in your luck. Your luck may not be in the chess world, but eventually, a chess
                            will be in your luck.
                        </MKTypography>
                        <Grid container spacing={1}>
                            <Grid item xs={10}>
                                cydshj kskcydshj kskcydshj kskcydshj kskcydshj kskcydshj ksk kskcydshj kskcydshj kskcydshj kskcydshj kskcydshj ksk
                                kskcydshj kskcydshj kskcydshj kskcydshj kskcydshj kskkskcydshj kskcydshj kskcydshj kskcydshj kskcydshj ksk
                                kskcydshj kskcydshj kskcydshj kskcydshj kskcydshj ksk.
                                kskcydshj kskcydshj kskcydshj kskcydshj kskcydshj ksk.
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </MKBox> */}
        </>
    );
}

export default TeamImc;