import * as React from 'react';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

import routes from "routes";
import footerRoutes from "footer.routes";

import { TypeAnimation } from "react-type-animation";
import bgPerks from "assets/images/bg-perks.jpeg"
import { currentCourseData } from '../AboutUs/sections/currentCourseData';
import CenteredBlogCard from 'examples/Cards/BlogCards/CenteredBlogCard';
import { Divider } from '@mui/material';
function Workshops() {
    return (
        <>
            <DefaultNavbar
                routes={routes}
                transparent
                light
            />
            <MKBox
                minHeight="40vh"
                width="100%"
                sx={{
                    backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${bgPerks})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <Container>
                    <Grid
                        container
                        item
                        xs={12}
                        lg={8}
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        sx={{ mx: "auto", textAlign: "center" }}
                    >
                        <MKTypography
                            variant="h4"
                            color="white"
                            sx={({ breakpoints, typography: { size } }) => ({
                                [breakpoints.down("md")]: {
                                    fontSize: size["3xl"],
                                },
                            })}
                        >
                            <TypeAnimation
                                preRenderFirstString={true}
                                sequence={[
                                    500,
                                    `Online Chess workshops.`, // initially rendered starting point
                                    1000,
                                    `Offline Chess workshops.`,
                                    1000,
                                    `Online Chess workshops.`,
                                    1000,
                                    `Offline Chess workshops.`,
                                    500,
                                ]}
                                speed={50}
                                // style={{ fontSize: '2em' }}
                                repeat={Infinity}
                            />
                        </MKTypography>

                        <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
                            Transforming novices into knights and beginners into bishops, one strategic move at a time!
                        </MKTypography>
                    </Grid>
                </Container>
            </MKBox>
            <Card
                sx={{
                    p: 2,
                    mx: { xs: 2, lg: 3 },
                    mt: -8,
                    mb: 4,
                    boxShadow: ({ boxShadows: { xxl } }) => xxl,
                }}
            >
                <Container>
                    <MKTypography variant="h3" color="info" opacity={0.8} mt={1} mb={3}>
                        Online/Offline Workshops
                    </MKTypography>
                    <Divider />
                </Container>
                <Container sx={{
                    mt: 8
                }}>
                    <Grid container spacing={3}>
                        {currentCourseData?.map((course) => {
                            return <Grid item xs={12} lg={4} mb={6} sx={{ mt: { xs: 3, lg: 0 } }}>
                                <CenteredBlogCard
                                    image={course?.image}
                                    title={course?.title}
                                    description={course?.description}
                                    action={{
                                        type: "internal",
                                        route: course?.route,
                                        color: course?.disabled ? "warning" : "info",
                                        label: course?.disabled ? <s>Join Now</s> : "Join Now",
                                        disabled: course?.disabled ?? course?.disabled
                                    }}
                                />
                            </Grid>
                        })}
                    </Grid>
                </Container>
            </Card>
            <MKBox pt={6} px={1} mt={6}>
                <DefaultFooter content={footerRoutes} />
            </MKBox>
        </>
    );
}

export default Workshops;