import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

import routes from "routes";
import footerRoutes from "footer.routes";

import { TypeAnimation } from "react-type-animation";
import MKButton from "components/MKButton";
import { Divider, Icon, Paper, Stack } from "@mui/material";
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";
import ApexChart from "pages/Common/Charts/Index";
import bgStar from "assets/images/bg-star.jpeg";
import { styled } from '@mui/system';
import ThumbGallery from "pages/Common/ThumbGallery/Index";

function SportsAchivements() {
    return (
        <>
            <DefaultNavbar
                routes={routes}
                transparent
                light
            />
            <MKBox
                minHeight="40vh"
                width="100%"
                sx={{
                    backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${bgStar})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <Container>
                    <Grid
                        container
                        item
                        xs={12}
                        lg={8}
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        sx={{ mx: "auto", textAlign: "center" }}
                    >
                        <MKTypography
                            variant="h4"
                            color="white"
                            sx={({ breakpoints, typography: { size } }) => ({
                                [breakpoints.down("md")]: {
                                    fontSize: size["3xl"],
                                },
                            })}
                        >
                            <TypeAnimation
                                preRenderFirstString={true}
                                sequence={[
                                    500,
                                    `Experience the power of possibility through our success stories.`, // initially rendered starting point
                                    1000,
                                    `Experience the power of possibility through our successful journeys.`,
                                    1000,
                                    `Experience the power of possibility through our success stories.`,
                                    1000,
                                    `Experience the power of possibility through our successful journeys.`,
                                    500,
                                ]}
                                speed={50}
                                // style={{ fontSize: '2em' }}
                                repeat={Infinity}
                            />
                        </MKTypography>

                        <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
                            Where dreams become reality and obstacles turn into stepping stones!
                        </MKTypography>
                    </Grid>
                </Container>
            </MKBox>
            <Card
                sx={{
                    p: 2,
                    mx: { xs: 2, lg: 3 },
                    mt: -8,
                    mb: 4,
                    boxShadow: ({ boxShadows: { xxl } }) => xxl,
                }}
            >
                <Container>
                    {/* 1st */}
                    <ThreeDCard>
                        <MKBox display="flex" justifyContent="space-between" alignItems="center" mt={6} mb={1}>
                            <MKTypography variant="h3">Name Surname</MKTypography>
                            <Grid item>
                                <MKTypography component="span" variant="body2" fontWeight="bold" color='#86c33a'>
                                    Age : 5 Years
                                </MKTypography>
                            </Grid>
                        </MKBox>
                        <Grid container spacing={3} mb={3}>
                            <Grid item>
                                <MKTypography component="span" variant="body2" color="text">
                                    Previous Rating&nbsp;
                                </MKTypography>
                                <MKTypography component="span" variant="body2" fontWeight="bold" color='#86c33a'>
                                    1800
                                </MKTypography>
                            </Grid>
                            <Grid item>
                                <MKTypography component="span" variant="body2" color="text">
                                    Current Fide Rating &nbsp;
                                </MKTypography>
                                <MKTypography component="span" variant="body2" fontWeight="bold" color='#86c33a'>
                                    1800
                                </MKTypography>
                            </Grid>
                        </Grid>
                        <MKTypography variant="body1" fontWeight="light" color="text">
                            In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available. <br />
                        </MKTypography>
                        <Divider />
                        <MKTypography variant="body2" fontWeight="light" color="text">
                            This enduring commitment to improvement defines his path, shaping not only his chess prowess but also instilling invaluable life lessons along the way.
                        </MKTypography>
                        <Divider />
                        <Grid container alignItems="center">
                            <Grid item xs={12} sx={{ mt: { xs: 6, lg: 3 } }}>
                                <ApexChart />
                            </Grid>
                        </Grid>
                    </ThreeDCard>
                    <Divider />
                    {/* 2nd */}
                    <ThreeDCard>
                        <MKBox display="flex" justifyContent="space-between" alignItems="center" mt={6} mb={1}>
                            <MKTypography variant="h3">Name Surname</MKTypography>
                            <Grid item>
                                <MKTypography component="span" variant="body2" fontWeight="bold" color='#86c33a'>
                                    Age : 5 Years
                                </MKTypography>
                            </Grid>
                        </MKBox>
                        <Grid container spacing={3} mb={3}>
                            <Grid item>
                                <MKTypography component="span" variant="body2" color="text">
                                    Previous Rating&nbsp;
                                </MKTypography>
                                <MKTypography component="span" variant="body2" fontWeight="bold" color='#86c33a'>
                                    1800
                                </MKTypography>
                            </Grid>
                            <Grid item>
                                <MKTypography component="span" variant="body2" color="text">
                                    Current Fide Rating &nbsp;
                                </MKTypography>
                                <MKTypography component="span" variant="body2" fontWeight="bold" color='#86c33a'>
                                    1800
                                </MKTypography>
                            </Grid>
                        </Grid>
                        <Divider />
                        <MKBox component="section" my={6}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={5}>
                                    <div className="container mx-auto mt-10">
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={10} className="relative z-10">
                                                <ThumbGallery />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                                <Grid item md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
                                    <MKTypography variant="body1" color="text" mb={3}>
                                        April 2024
                                    </MKTypography>
                                    <MKTypography variant="body1" fontWeight="light" color="text">
                                        In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available. <br />
                                    </MKTypography>
                                    <Divider />
                                    <MKTypography variant="body2" fontWeight="light" color="text">
                                        This enduring commitment to improvement defines his path, shaping not only his chess prowess but also instilling invaluable life lessons along the way.
                                    </MKTypography>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            Your luck may not be in the chess world, but eventually, a chess
                                            will be in your luck.
                                            Your luck may not be in the chess world, but eventually, a chess
                                            will be in your luck.
                                            Your luck may not be in the chess world, but eventually, a chess
                                            will be in your luck.
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </MKBox>
                        <Divider />
                        <Grid container alignItems="center">
                            <Grid item xs={12} sx={{ mt: { xs: 6, lg: 3 } }}>
                                <ApexChart />
                            </Grid>
                        </Grid>
                    </ThreeDCard>
                </Container>

                <br />

            </Card >
            <MKBox pt={6} px={1} mt={6}>
                <DefaultFooter content={footerRoutes} />
            </MKBox>
        </>
    );
}

export default SportsAchivements;

// Custom styled Paper component for 3D effect
const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(4),
    borderRadius: theme.spacing(2),
    boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.2)',
    transition: 'transform 0.3s',
    '&:hover': {
        transform: 'translateY(-5px)',
    },
}));

const ThreeDCard = ({ children }) => {
    return (
        <StyledPaper elevation={0} variant="outlined">
            {/* Your existing code */}
            {/* <Grid container justifyContent="center" py={1}> */}
            {/* <Grid item xs={12} md={10} mx={{ xs: "auto", sm: 6, md: 1 }}> */}
            {children}
            {/* </Grid> */}
            {/* </Grid> */}
        </StyledPaper>
    );
};