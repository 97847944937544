import post1 from "assets/images/examples/1.png";
import post2 from "assets/images/examples/2.png";
import post3 from "assets/images/examples/3.png";
import post4 from "assets/images/examples/4.png";
import post5 from "assets/images/examples/5.png";
import post6 from "assets/images/examples/6.png";
export const postsData = [{
    image: post1,
    title: "Online camp post by ChessbaseIndia - 2024",
    description: "Solve 4 tough positions from IM Manush Shah and the online workshop content ...",
    route: "https://www.chessbase.in/news/Training-camp-with-International-Master-Manush-Shah",
},
{
    image: post2,
    title: "IM title post by ChessbaseIndia - 2022",
    description: "Manush Shah becomes the latest International Master of India. Journey highlights and the best games ...",
    route: "https://www.chessbase.in/news/Manush-Shah-becomes-the-latest-International-Master-of-India"
},
{
    image: post3,
    title: "2405 ELO (+389 rating), 2 IM norms in 1.5 yrs with K-factor 20 journey by Manush Shah - 2019",
    description: "Breaking through stagnation - The story of how Manush Shah reached 2405 Elo ...",
    route: "https://www.chessbase.in/news/Story-of-Manush-Shah",
},
{
    // video
    image: post4,
    title: "News 18 Gujarat : IM Manush Shah's nomination for the Eklayva Award. - 2024",
    description: "દુનિયામાં ડંકો વગાડનાર ચેસ માસ્ટર માનુષ શાહ હવે એકલવ્ય સિનિયર એવોર્ડનો દાવેદાર ...",
    route: "https://gujarati.news18.com/photogallery/ahmedabad/chess-master-manush-shah-who-played-in-the-world-is-now-a-contender-for-the-eklavya-senior-award-az-1763166.html"
},
{
    // video
    image: post5,
    title: "Divy Bhaskar : IM Manush Shah's nomination for the Eklayva Award - 2024",
    description: "ગુજરાતનું ગૌરવ:એકલવ્ય સિનિયર એવોર્ડ માટે અમદાવાદનો યુવા ચેસ માસ્ટર માનુષ શાહ દાવેદાર ...",
    route: "https://www.divyabhaskar.co.in/local/gujarat/ahmedabad/news/ahmedabads-young-chess-master-manush-shah-nominated-for-eklavya-senior-award-132848663.html",
},
{
    image: post6,
    title: "Gujarat Samachar : IM Manush Shah's nomination for the Eklayva Award - 2024",
    description: "ચેસ માસ્ટર માનુષ શાહે ગુજરાતનું ગૌરવ વધાર્યું, હવે એકલવ્ય સિનિયર એવોર્ડનો દાવેદાર ...",
    route: "https://www.gujaratsamachar.com/news/sports/ahmedabad-chess-maestro-manush-shah-brings-glory-to-gujarat",
}]

export const videoPost = [{
    image: "https://upload.wikimedia.org/wikipedia/commons/2/24/News_18_India.png",
    title: "News 18 : Feturing IM manush shah 2022",
    route: "https://www.facebook.com/100069091103715/videos/735086940193751",
},
{
    video: "https://www.youtube.com/embed/xTAY5u1s_C4",
    title: "Gujarat First : Feturing IM manush shah 2024",
    description: "ગુજરાતનું ગૌરવ:એકલવ્ય સિનિયર એવોર્ડ માટે અમદાવાદનો યુવા ચેસ માસ્ટર માનુષ શાહ દાવેદાર ...",
    route: "https://www.youtube.com/embed/xTAY5u1s_C4"
}]