import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import MKBox from "components/MKBox";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import LeadForm from "./LeadForm/Index";

function ComingSoon({ show, setShow }) {
    return (
        <MKBox component="section" py={1}>
            <Container>
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} lg={6}>
                        {/* <Grid container justifyContent="flex-start">
                            <MKBox component="form" p={2} method="post">
                                <MKBox px={3} py={{ xs: 2, sm: 6 }}>
                                    <MKTypography variant="h2" mb={1}>
                                        Register Yourself!
                                    </MKTypography>
                                    <MKTypography variant="body1" color="text" mb={2}>
                                        We&apos;ll soon contact you.
                                    </MKTypography>
                                </MKBox>
                                <MKBox pt={0.5} pb={3} px={3}>
                                    <Grid container>
                                        <Grid item xs={12} pr={1} mb={6}>
                                            <MKInput
                                                variant="standard"
                                                label="Name"
                                                placeholder="Full Name"
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} pr={1} mb={6}>
                                            <MKInput
                                                variant="standard"
                                                label="Mobile Number"
                                                placeholder="Your Mobile Number"
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} pr={1} mb={6}>
                                            <MKInput
                                                variant="standard"
                                                label="Email"
                                                placeholder="Email Address"
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} pr={1} mb={6}>
                                            <Typography variant="body2" gutterBottom color={'black'}>
                                                Select Category
                                            </Typography>
                                            <Dropdown option={option} setOption={setOption} />
                                        </Grid>
                                        <Grid item xs={12} pr={1} mb={6}>
                                            <MKInput
                                                variant="standard"
                                                label="Current Rating (Optional)"
                                                placeholder="Your Current Rating"
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} pr={1} mb={6}>
                                            <MKInput
                                                variant="standard"
                                                label="Your message (Optional)"
                                                placeholder="I want to say that..."
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                                multiline
                                                rows={6}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        md={6}
                                        justifyContent="flex-end"
                                        textAlign="right"
                                        ml="auto"
                                    >
                                        <MKButton variant="gradient" color="info">
                                            Register
                                        </MKButton>
                                    </Grid>
                                </MKBox>
                            </MKBox>
                        </Grid> */}
                        <LeadForm />
                    </Grid>
                    <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
                        <CenteredBlogCard
                            image="https://img.freepik.com/free-vector/opening-soon-background-grunge-style_23-2147868024.jpg"
                            title="Offline Chess Batches Opening Soon!"
                            description="Stay tuned for exciting chess training sessions. Improve your skills and strategies with our expert coaches."
                            action={{
                                type: "internal",
                                route: "/contact-us",
                                color: "info",
                                label: "Contact Us for more information",
                            }}
                        />
                    </Grid>
                </Grid>
            </Container>
        </MKBox>
        // <div className="bg-gray-200 min-h-screen flex items-center justify-center">
        //     <div className="max-w-lg bg-white p-8 rounded shadow-md">
        //         <h2 className="text-3xl font-bold mb-4 text-center">Offline Chess Batches Coming Soon!</h2>
        //         <p className="text-gray-700 text-center mb-6">Stay tuned for exciting chess training sessions. Improve your skills and strategies with our expert coaches!</p>
        //         <div className="flex justify-center">
        //             <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
        //                 Subscribe for Updates
        //             </button>
        //         </div>
        //     </div>
        // </div>
    );
}

export default ComingSoon;
