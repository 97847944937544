export const data = [
    {
        opponent1: "Manush Shah : 1",
        opponent2: "Gukesh D : 0",
        quote: "IM Manush Shah's tough game with GM Gukesh D ended in a great win, providing an excellent experience.",
        pgn: `[Event "Delhi Open 2018"]
    [Site "?"]
    [Date "2018.01.11"]
    [Round "4.37"]
    [White "Manush Shah"]
    [Black "Gukesh D"]
    [Result "1-0"]
    [ECO "D00"]
    [WhiteElo "2148"]
    [BlackElo "2352"]
    [Annotator "pav"]
    [PlyCount "73"]
    [EventDate "2018.??.??"]
    [SourceDate "2018.01.11"]
    
    1. d4 Nf6 2. Nf3 g6 3. Nc3 d5 4. Bf4 Bg7 5. e3 O-O 6. Be2 Bg4 7. h4 Nbd7 8. Ne5
    Bxe2 9. Qxe2 h5 10. O-O-O c6 11. f3 (11. Nd3 Ne4 12. Qe1) 11... Qc8 (11... Nxe5
    12. Bxe5 Nd7 13. Bxg7 Kxg7 14. g4 Rh8) 12. Rdg1 (12. Nd3 b5 (12... Ne8 13. g4
    Ndf6 14. Rhg1) 13. e4 dxe4 (13... e6 14. Bd6) 14. fxe4 Ng4 15. e5 Nb6 (15... e6
    16. Ne4) 16. Nc5) 12... Nxe5 13. Bxe5 (13. dxe5 Nd7 14. g4 hxg4 15. e6 (15.
    Rxg4 Nxe5 16. Bxe5 Bxe5 17. h5 Qf5 18. e4) 15... fxe6 16. Rxg4) 13... Nd7 14.
    Bxg7 Kxg7 15. g4 Rh8 16. e4 e6 17. f4 (17. Kb1) 17... hxg4 18. Rxg4 Nf6 19.
    Rg5 dxe4 20. Nxe4 Qc7 21. Qf3 Nd5 22. f5 exf5 23. Qxf5 Qf4+ 24. Qxf4 Nxf4 25.
    Ng3 Rae8 26. Kd2 Rd8 27. Rg4 Ne6 28. Kc3 Rd5 29. h5 g5 30. Rf1 Kf8 31. Re4 Nf4
    32. Rfe1 Rd8 33. Kc4 b5+ 34. Kc5 Rh6 35. Re5 Ne6+ 36. Kxc6 Rc8+ 37. Kb7 1-0`},
    {
        opponent1: "Manush Shah : 1",
        opponent2: "GM Nikcevic Nebojsa : 0",
        quote: "IM Manush Shah's tough game with GM Nikcevic Nebojsa ended in a great win.",
        pgn: `[Event "Montenegro"]
    [Site "?"]
    [Date "2018.10.23"]
    [Round "4"]
    [White "Manush Shah"]
    [Black "GM Nikcevic Nebojsa"]
    [Result "1-0"]
    [ECO "D40"]
    [WhiteElo "2302"]
    [BlackElo "2424"]
    [Annotator "pav"]
    [PlyCount "125"]
    [EventDate "2018.??.??"]
    [SourceDate "2018.10.24"]
    
    1. d4 Nf6 2. Nf3 e6 3. e3 c5 4. Bd3 Nc6 5. O-O d5 6. c4 dxc4 7. Bxc4 cxd4 8.
    exd4 Be7 9. Nc3 O-O 10. h3 b6 11. Be3 Bb7 12. Qe2 Na5 13. Bd3 Nd5 14. Rfe1 Nb4
    15. Be4 Bxe4 16. Nxe4 Rc8 17. Nc3 Nd5 18. Rac1 Nxc3 19. bxc3 Nc4 20. Bf4 Qd5
    21. Nd2 Rfd8 22. Ne4 Rc6 23. Rcd1 h6 24. Bc1 Nd6 25. Rd3 Qxe4 26. Qxe4 Nxe4 27.
    Rxe4 Rc4 28. Re2 Kf8 29. Bd2 Rd5 30. Be1 Bd6 31. g3 Ra5 32. Rdd2 Ke7 33. Kg2
    Kd7 34. Rb2 Kc6 35. Re3 Ra3 36. Rf3 f6 37. Re3 e5 38. dxe5 Bxe5 39. f4 Bd6 40.
    Rd2 Bc5 41. Re6+ Kb5 42. Rb2+ Ka6 43. Ree2 b5 44. Re6+ Bb6 45. Re7 Rc7 46. Re4
    Rc6 47. f5 Rc7 48. g4 Rd7 49. c4 bxc4 50. Rxc4 Rad3 51. Bf2 Rd2 52. Rcc2 Rxc2
    53. Rxc2 Bxf2 54. Kxf2 Kb5 55. Rb2+ Ka4 56. Rb3 Re7 57. Kg3 a5 58. Kh4 Re2 59.
    Rb7 Rxa2 60. Rxg7 Rb2 61. Kh5 Ka3 62. Kxh6 Rh2 63. Kg6 1-0`},
    {
        opponent1: "Jay Kundaliya : 0",
        opponent2: "Manush Shah : 1",
        quote: "",
        pgn: `[Event "National B State"]
        [Site "Graz"]
        [Date "2020.03.02"]
        [Round "6"]
        [White "Jay Kundaliya"]
        [Black "Manush Shah"]
        [Result "0-1"]
        [ECO "B33"]
        [WhiteElo "2071"]
        [BlackElo "2322"]
        [Annotator "pav"]
        [PlyCount "46"]
        [EventDate "2020.02.14"]
        [SourceDate "2020.03.02"]
        
        1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 e5 6. Ndb5 d6 7. Nd5 Nxd5 8.
        exd5 Nb8 9. c4 a6 10. Nc3 Be7 11. Bd3 O-O 12. O-O f5 13. f3 Nd7 14. Be3 Qe8 15.
        b4 Qh5 16. c5 f4 17. Bf2 Rf6 (17... Nf6 18. Qb3 g5 19. Ne4 Nxe4 20.
        Bxe4 g4) (17... dxc5 18. d6 Bd8 (18... Bxd6 19. Bc4+) 19. bxc5) 18. c6 Rh6
        19. h4 (19. h3 Nf8 20. cxb7 (20. Bb6 Bxh3 21. gxh3 Qxh3 22. Qc2 Rh5 23.
        Ne4) 20... Bxh3 21. bxa8=Q Bxg2) 19... Nf8 20. Na4 Ng6 21. Nb6 Nxh4 22.
        Bxh4 Bxh4 23. Rf2 (23. Re1 Bg3) 23... Bd8  0-1`
    }
]