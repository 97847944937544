import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import MKBox from "components/MKBox";

import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import { currentCourseData } from "./currentCourseData";

function Information() {
  return (
    <MKBox component="section" py={12}
    //  sx={{ background: `linear-gradient(to right, #F2F2F2 30%, transparent 40%)` }}
    >
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="supervised_user_circle"
                    title="OUR MISSION"
                    description="We are committed to making chess education accessible and rewarding for all, inspiring and empowers individuals through chess, fostering strategic thinking, intellectual growth, and personal development."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="verified_user"
                    title="OUR APPROACH"
                    description="We offer personalized instruction, a comprehensive curriculum, and interactive learning for students to thrive and achieve their chess goals."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="category"
                    title="Our PHILOSOPHY"
                    description="We value excellence, integrity, inclusivity, and passion, guiding everything we do to share the transformative power of chess with the world."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="done_all"
                    title="OUR STRONG VALUES"
                    description="Excellence, Integrity, Community, Growth, Passion."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={currentCourseData?.[0]?.image}
              title={currentCourseData?.[0]?.title}
              description={currentCourseData?.[0]?.description}
              action={{
                type: "internal",
                route: currentCourseData?.[0]?.route,
                color: "info",
                label: "find out more",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
