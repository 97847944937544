// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";

// React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logo-ct-dark.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "IMCHESS",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/",
    },
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com/",
    },
    {
      icon: <InstagramIcon />,
      link: "https://twitter.com/",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com/channel/UCVyTG4sCw-rOvB9oHkzZD1w",
    },
  ],
  menus: [
    {
      name: "IMCHESS",
      items: [
        { name: "Home", href: "/" },
        { name: "About us", href: "/about-us" },
        { name: "Coach Profile", href: "/coach-profile" },
      ],
    },
    {
      name: "resources",
      items: [
        { name: "Open Job Profiles", href: "/job" },
        { name: "Online Batches", href: "/batch/online" },
        { name: "Offline Batches", href: "/batch/offline" },
        // { name: "affiliate program", href: "/affiliates/new" },
      ],
    },
    {
      name: "help & support",
      items: [
        { name: "contact us", href: "/contact-us" },
        { name: "knowledge center", href: "/knowledge-center" },
        // { name: "sponsorships", href: "/sponsorships" },
      ],
    },
    // {
    //   name: "legal",
    //   items: [
    //     { name: "terms & conditions", href: "/terms" },
    //     { name: "privacy policy", href: "/privacy" },
    //     // { name: "licenses (EULA)", href: "/license" },
    //   ],
    // },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} IMCHESS{" "}.
    </MKTypography>
  ),
};
