import Icon from "@mui/material/Icon";

import AboutUs from "layouts/pages/landing-pages/about-us";
import Author from "layouts/pages/landing-pages/author";

import Batches from "pages/LandingPages/Batches/Index";
import NavContactUs from "pages/LandingPages/ContactUs/NavContactUs";
import Gallery from "pages/Gallery";
import SportsAchivements from "pages/LandingPages/Achivements/Sports/Index";
import BestGames from "pages/LandingPages/Achivements/BestGames/Index";
import SuccessStories from "pages/LandingPages/Achivements/SuccessStories/Index";
import ImchessInfo from "pages/LandingPages/ImchessInfo/Index";
import KnowledgeCenter from "pages/LandingPages/KnowledgeCenter/Index";
import NewsBlogs from "pages/NewsBlogs/Index";
import Workshops from "pages/LandingPages/Workshops/Index";

const routes = [
  {
    name: "Home",
    icon: <Icon>home</Icon>,
    href: "/",
  },
  {
    name: "About Us",
    icon: <Icon>psychologyAlt</Icon>,
    route: "/about-us",
    component: <AboutUs />,
  },
  {
    name: "Coach Profile",
    icon: <Icon>schoolOutlinedIcon';</Icon>,
    route: "/coach-profile",
    component: <Author />,
  },
  {
    name: "Batches",
    icon: <Icon>classOutlinedIcon</Icon>,
    collapse: [
      {
        name: "Online",
        description: "Elevate your skills with our online batch!",
        route: "/batch/online",
        component: <Batches />
      },
      {
        name: "Offline",
        description: "Dive into immersive learning experiences with our offline batch!",
        route: "/batch/offline",
        component: <Batches />
      },
      {
        name: "Workshops",
        description: "Transforming novices into knights and beginners into bishops, one strategic move at a time!",
        route: "/workshops",
        component: <Workshops />
      },
    ],
  },
  {
    name: "Gallery",
    icon: <Icon>article</Icon>,
    collapse: [
      {
        name: "IMCHESS Gallery",
        description: "Captured the essence of our academy's journey through our vibrant.",
        route: "/gallery/imc",
        component: <Gallery />
      },
      {
        name: "IMCHESS Team Gallery",
        description: "Step into the world of elite coaching through our IM Coach.",
        route: "/gallery",
        component: <Gallery />
      },
    ],
  },
  {
    name: "Achievements",
    icon: <Icon>grade</Icon>,
    collapse: [
      {
        name: "Success Stories",
        description: "Experience the power of possibility through our success stories.",
        route: "/imchess-success-stories",
        component: <SuccessStories />
      },
      {
        name: "Sports Achievements",
        description: "Step into the winner's circle with our array of sports achievements.",
        route: "/imchess-sports-achivements",
        component: <SportsAchivements />
      },
      {
        name: "Best Games",
        description: "Experience the strategic with our collection of Chess Best Games.",
        route: "/best-games",
        component: <BestGames />
      },
    ],
  },
  {
    name: "Contact Us",
    icon: <Icon>contactsIcon </Icon>,
    route: "/contact-us",
    component: <NavContactUs />,
  },
  {
    route: "/imchess-info",
    component: <ImchessInfo />,
  },
  {
    route: "/knowledge-center",
    component: <KnowledgeCenter />,
  },
  {
    route: "/news-blogs",
    component: <NewsBlogs />,
  },
  // {
  //   name: "sections",
  //   icon: <Icon>view_day</Icon>,
  //   collapse: [
  //     {
  //       name: "page sections",
  //       description: "See all sections",
  //       dropdown: true,
  //       collapse: [
  //         {
  //           name: "page",
  //           route: "/",
  //           component: <PageHeaders />,
  //         },
  //         {
  //           name: "features",
  //           route: "/",
  //           component: <Features />,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   name: "Login",
  //   icon: <Icon>login </Icon>,
  //   route: "/authentication/login",
  //   component: <SignIn />,
  // },
];

export default routes;