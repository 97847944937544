import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const PyramidChart = ({ categories, data, name, text }) => {
  const [chartData, setChartData] = useState({
    series: [
      {
        name: name,
        data: data,
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 350,
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
          distributed: true,
          barHeight: '90%',
          isFunnel: true,
        },
      },
      colors: [
        '#86c33a',
        '#000',
        '#000',
        '#000',
        '#000',
        '#000',
        '#000',
        '#000',
        '#000',
        '#000',
        '#000',
      ],
      dataLabels: {
        enabled: true,
        formatter: function (val, opt) {
          return opt.w.globals.labels[opt.dataPointIndex]
        },
        dropShadow: {
          enabled: true,
        },
      },
      title: {
        text: text,
        align: 'middle',
      },
      xaxis: {
        categories: categories,
      },
      legend: {
        show: false,
      },
    }
  });

  return (
    <div>
      <div id="chart">
        <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={350} />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default PyramidChart;
