import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

import image1 from "assets/images/Multiple/image1.jpeg";
import image2 from "assets/images/Multiple/image2.jpeg";
import image3 from "assets/images/Multiple/image3.jpeg";
import image9 from "assets/images/Multiple/image9.jpeg";
import MKButton from "components/MKButton";
import { Icon } from "@mui/material";
import { useNavigate } from "react-router-dom";

function WhyUs() {
    const navigate = useNavigate();
    return (
        <MKBox
            component="section"
            variant="gradient"
            bgColor="dark"
            position="relative"
            py={6}
            px={{ xs: 2, lg: 0 }}
            mx={-2}
        >
            <Container>
                <Grid container>
                    <Grid item xs={12} md={8} sx={{ mb: 6 }}>
                        <MKTypography variant="h3" color="white">
                            Why Choose Us?
                        </MKTypography>
                        <MKTypography variant="body2" color="white" opacity={0.8}>
                            Make a move and join now to elevate your game, master the board, and conquer your opponents.
                        </MKTypography>
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <MKButton variant="gradient"
                            onClick={() => navigate('/imchess-info')}
                            color="info" sx={{
                                width: "max-content",
                                display: "flex",
                                alignItems: "center",
                                marginBottom: '25px',

                                "& .material-icons-round": {
                                    transform: `translateX(3px)`,
                                    transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                                },

                                "&:hover .material-icons-round, &:focus .material-icons-round": {
                                    transform: `translateX(6px)`,
                                },
                            }}>
                            Discover More Perks <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                        </MKButton>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <MKBox mb={1}>
                            <HorizontalTeamCard
                                image={image1}
                                name="Why Choose IMCHESS Academy?"
                                position={{ color: "info", label: "Learn from a International master" }}
                                description="Our International Master spills all their winning secrets and experience."
                            />
                        </MKBox>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <MKBox mb={1}>
                            <HorizontalTeamCard
                                image={image2}
                                name="Continuous Progress Tracking"
                                position={{ color: "info", label: "Benefit from regular progress assessments" }}
                                description="Feedback sessions to track your development, identify areas for improvement, and set achievable goals to keep you motivated."
                            />
                        </MKBox>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <MKBox mb={{ xs: 1, lg: 0 }}>
                            <HorizontalTeamCard
                                image={image3}
                                name="Tournaments Preparations"
                                position={{ color: "info", label: "Tournament ready! We will sharpen your game so you can bring home the wins." }}
                                description="Gain a competitive edge with specialized training programs designed to sharpen your skills and readiness for tournaments at all levels."
                            />
                        </MKBox>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <MKBox mb={{ xs: 1, lg: 0 }}>
                            <HorizontalTeamCard
                                image={image9}
                                name="Expert Coaching"
                                position={{ color: "info", label: "Benefit from personalized guidance" }}
                                description="Level up with our expert IM coach!
                                Experience rapid improvement with personalized strategies from our Coach, who crafts every lesson to your unique skill level and goals.
                               making progress fast."
                            />
                        </MKBox>
                    </Grid>
                </Grid>
            </Container>
        </MKBox>
    );
}

export default WhyUs;