// src/components/Meta.js
import React from 'react';
import { Helmet } from 'react-helmet';

const Meta = () => {
    return (
        <Helmet>
            <title>IMCHESS Academy</title>
            <meta name="description" content="This is the description of my website." />
            <meta name="keywords" content="React, SEO, Meta Tags" />

            {/* Open Graph tags for social media */}
            <meta property="og:title" content="IMCHESS Academy" />
            <meta property="og:description" content="This is the description of my website." />
            <meta property="og:image" content="https://mlpr3v7xng2n.i.optimole.com/cb:C7ow.1822c/w:1600/h:1073/q:mauto/f:best/ig:avif/https://www.batterseachessclub.org.uk/wp-content/uploads/2020/11/pasted-image-0.jpg" />
            {/* <meta property="og:url" content="http://example.com/" /> */}
            <meta property="og:type" content="website" />

            {/* Twitter Card tags for Twitter */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="My Website Title" />
            <meta name="twitter:description" content="This is the description of my website." />
            <meta name="twitter:image" content="https://mlpr3v7xng2n.i.optimole.com/cb:C7ow.1822c/w:1600/h:1073/q:mauto/f:best/ig:avif/https://www.batterseachessclub.org.uk/wp-content/uploads/2020/11/pasted-image-0.jpg" />
        </Helmet>
    );
};

export default Meta;