import React from "react";
import { HashLoader } from "react-spinners";
import "../../assets/css/loading.css";

const Loading = () => {
    return (
        <div className="loading-conainter">
            <HashLoader color="#fff" size={120} />
        </div>
    );
};

export default Loading;