// @mui material components
import { AppBar, Tab, Tabs } from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// React components
import MKBox from "components/MKBox";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import ComingSoon from "pages/Common/ComingSoon";

function CourseCard({ data, activeTab, setActiveTab }) {
    console.log("activeTab ", activeTab);
    const handleTabType = (event, newValue) => {event.preventDefault(); setActiveTab(newValue)};
    return (
        <>
            {data?.length > 0 ? <><Container>
                <Grid container item justifyContent="center" xs={12} lg={6} mx="auto">
                    <AppBar position="static">
                        <Tabs value={activeTab} onChange={handleTabType}>
                            <Tab label="All" />
                            <Tab label="Beginner" />
                            <Tab label="Intermediate" />
                            <Tab label="Advanced" />
                            <Tab label="Individual" />
                        </Tabs>
                    </AppBar>
                </Grid>
            </Container>
                <MKBox component="section" py={6}>
                    <Container>
                        <Grid container spacing={6} alignItems="center">
                            {data?.map((course) =>
                                <Grid item xs={12} lg={4} sx={{ mt: { xs: 3, lg: 0 } }}>
                                    <CenteredBlogCard
                                        image={course?.image}
                                        title={course?.title}
                                        description={course?.description}
                                        action={{
                                            type: "internal",
                                            route: "pages/company/about-us",
                                            color: "info",
                                            label: "find out more",
                                        }}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Container>
                </MKBox></>
                : <ComingSoon />}
        </>
    );
}

export default CourseCard;
