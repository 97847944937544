import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

import routes from "routes";
import footerRoutes from "footer.routes";

import { TypeAnimation } from "react-type-animation";
import CourseCard from "./Sections/CourseCard";
import MKBadge from "components/MKBadge";
import { useLocation, useNavigate } from "react-router-dom";
import { offline, online } from "./courses";
import { useEffect, useState } from "react";
import bgBatch from "assets/images/bg-batches.jpeg";

function Batches() {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(0);
    const [coursedata, setCourseData] = useState(pathname === '/batch/online' ? online : pathname === '/batch/offline' && offline);
    let dataCourse = [];
    if (pathname === '/batch/online') {
        dataCourse = online
    }
    if (pathname === '/batch/offline') {
        dataCourse = offline
    }

    function filterByDays(days) {
        let data = pathname === '/batch/online' ? online : pathname === '/batch/offline' && offline;
        return data.filter(item => {
            return days.every(day => item.days.includes(day));
        });
    }

    useEffect(() => {
        // if pathename changes then update active tab to 0
        setActiveTab(0);
        if (pathname === '/batch/online') {
            setCourseData(online);
        }
        if (pathname === '/batch/offline') {
            setCourseData(offline);
        }
        // setCourseData(pathname === '/batch/online' ? online : pathname === '/batch/offline' && offline)
    }, [pathname])

    useEffect(() => {
        console.log("activeTabactiveTabactiveTab", activeTab);

        if (activeTab == 1) {
            setCourseData(filterByDays(['All', "Beginner"]));
        } else if (activeTab == 2) {
            setCourseData(filterByDays(['All', "Intemediate"]));
        } else if (activeTab == 3) {
            setCourseData(filterByDays(['All', "Advance"]));
        } else if (activeTab == 4) {
            setCourseData(filterByDays(['All', "Individual"]));
        } else {
            // all
            setCourseData(pathname === '/batch/online' ? online : pathname === '/batch/offline' && offline);
        }
    }, [activeTab])

    return (
        <>
            <DefaultNavbar
                routes={routes}
                transparent
                light
            />
            <MKBox
                minHeight="75vh"
                width="100%"
                sx={{
                    backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${bgBatch})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <Container>
                    <Grid
                        container
                        item
                        xs={12}
                        lg={8}
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        sx={{ mx: "auto", textAlign: "center" }}
                    >
                        <MKTypography
                            variant="h4"
                            color="white"
                            sx={({ breakpoints, typography: { size } }) => ({
                                [breakpoints.down("md")]: {
                                    fontSize: size["3xl"],
                                },
                            })}
                        >
                            {pathname === '/batch/online' && <TypeAnimation
                                preRenderFirstString={true}
                                sequence={[
                                    500,
                                    'Elevate your skills with our online batches!',
                                    1000,
                                    'Elevate your skills and learn from anywhere!',
                                    1000,
                                    'Elevate your skills with our online batches!',
                                    1000,
                                    'Elevate your skills and learn from anywhere!',
                                    500,
                                ]}
                                speed={50}
                                // style={{ fontSize: '2em' }}
                                repeat={Infinity}
                            />}

                            {pathname === '/batch/offline' && <TypeAnimation
                                preRenderFirstString={true}
                                sequence={[
                                    500,
                                    'Dive into immersive learning experiences with our offline batches!',
                                    1000,
                                    'Dive into immersive learning experiences and unlock your potential!',
                                    1000,
                                    'Dive into immersive learning experiences with our offline batch!',
                                    1000,
                                    'Dive into immersive learning experiences and unlock your potential!',
                                    500,
                                ]}
                                speed={50}
                                // style={{ fontSize: '2em' }}
                                repeat={Infinity}
                            />}
                        </MKTypography>
                        <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
                            {pathname === '/batch/online' && 'Expert-Led Sessions, Interactive Learning And Learn From Anywhere!'}
                            {pathname === '/batch/offline' && 'Discover, Engage And hands-on practice await!'}
                        </MKTypography>
                        <MKButton color="default" onClick={() => navigate('/contact-us')} sx={{ color: ({ palette: { dark } }) => dark.main }}>
                            Join Now
                        </MKButton>
                        <MKTypography variant="h6" color="white" mt={8} mb={1}>
                            Find us on
                        </MKTypography>
                        <MKBox display="flex" justifyContent="center" alignItems="center">
                            <MKTypography component="a" variant="body1" color="white" href="#" mr={3}>
                                <i className="fab fa-facebook" />
                            </MKTypography>
                            <MKTypography component="a" variant="body1" color="white" href="#" mr={3}>
                                <i className="fab fa-instagram" />
                            </MKTypography>
                            <MKTypography component="a" variant="body1" color="white" href="#" mr={3}>
                                <i className="fab fa-twitter" />
                            </MKTypography>
                            <MKTypography component="a" variant="body1" color="white" href="#">
                                <i className="fab fa-youtube" />
                            </MKTypography>
                        </MKBox>
                    </Grid>
                </Container>
            </MKBox>
            <Card
                sx={{
                    p: 2,
                    mx: { xs: 2, lg: 3 },
                    mt: -8,
                    mb: 4,
                    boxShadow: ({ boxShadows: { xxl } }) => xxl,
                }}
            >
                <Container>
                    <Grid
                        container
                        item
                        xs={12}
                        lg={6}
                        flexDirection="column"
                        alignItems="center"
                        sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
                    >
                        <MKBadge
                            variant="contained"
                            color="info"
                            badgeContent={pathname === '/batch/online' ? "Grow Virtually!" : "Conquer The Board!"}
                            container
                            sx={{ mb: 2 }}
                        />
                        <MKTypography variant="h2" fontWeight="bold">
                            Our {pathname === '/batch/online' ? 'Online' : pathname === '/batch/offline' && 'Offline'} courses
                        </MKTypography>
                    </Grid>
                </Container>
                <CourseCard data={coursedata} activeTab={activeTab} setActiveTab={setActiveTab} />
            </Card>
            <MKBox pt={6} px={1} mt={6}>
                <DefaultFooter content={footerRoutes} />
            </MKBox>
        </>
    );
}

export default Batches;