import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";
import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCard";

import post4 from "assets/images/examples/blog2.jpg";
import { postsData } from "./postsData";

function Places() {
  return (
    <MKBox component="section" py={2}>
      <Container>
        <Grid container item xs={12} lg={6}>
          <MKTypography variant="h3" mb={6}>
            Check our latest news/posts
          </MKTypography>
        </Grid>
        <Grid container spacing={3}>
          {postsData?.map((post) => {
            return <Grid item xs={12} sm={6} lg={3}>
              <TransparentBlogCard
                image={post?.image}
                title={post?.title}
                description={post?.description}
                action={{
                  type: "internal",
                  route: post?.route,
                  color: "info",
                  label: "read more",
                }}
              />
            </Grid>
          })}
          <Grid item xs={12} sm={6} lg={3}>
            <BackgroundBlogCard
              image={post4}
              title="News/Blogs"
              description="Explore our latest news and blogs for more insightful stories."
              action={{
                type: "internal",
                route: "/news-blogs",
                label: "read more",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Places;
