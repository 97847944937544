import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import { useState } from "react";
import { Typography } from "@mui/material";
import Dropdown from "../Dropdown";

function LeadForm() {
    const options = [{
        label: "Select Category", value: ""
    }, {
        label: "Basic", value: "Basic"
    }, {
        label: "Intermediate", value: "Intermediate"
    }, {
        label: "Advance", value: "Advance"
    }, {
        label: "Individual", value: "Individual"
    }, {
        label: "Custom", value: "Custom"
    }];
    const [option, setOption] = useState(options);

    return (
        <MKBox component="section">
            <Container>
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} lg={12}>
                        <Grid container justifyContent="flex-start">
                            <MKBox component="form" p={2} method="post">
                                <MKBox px={3} py={{ xs: 2, sm: 6 }}>
                                    <MKTypography variant="h2" mb={1}>
                                        Contact Us!
                                    </MKTypography>
                                    <MKTypography variant="body1" color="text" mb={2}>
                                        We&apos;ll soon contact you.
                                    </MKTypography>
                                </MKBox>
                                <MKBox pt={0.5} pb={3} px={3}>
                                    <Grid container>
                                        <Grid item xs={12} pr={1} mb={6}>
                                            <MKInput
                                                variant="standard"
                                                label="Name"
                                                placeholder="Full Name"
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} pr={1} mb={6}>
                                            <MKInput
                                                variant="standard"
                                                label="Mobile Number"
                                                placeholder="Your Mobile Number"
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} pr={1} mb={6}>
                                            <MKInput
                                                variant="standard"
                                                label="Email"
                                                placeholder="Email Address"
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} pr={1} mb={6}>
                                            <Typography variant="body2" gutterBottom color={'black'}>
                                                Select Category
                                            </Typography>
                                            <Dropdown option={option} setOption={setOption} />
                                        </Grid>
                                        <Grid item xs={12} pr={1} mb={6}>
                                            <MKInput
                                                variant="standard"
                                                label="Current Rating (Optional)"
                                                placeholder="Your Current Rating"
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} pr={1} mb={6}>
                                            <MKInput
                                                variant="standard"
                                                label="Your message (Optional)"
                                                placeholder="I want to say that..."
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                                multiline
                                                rows={6}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        md={6}
                                        justifyContent="flex-end"
                                        textAlign="right"
                                        ml="auto"
                                    >
                                        <MKButton variant="gradient" color="info">
                                            Register
                                        </MKButton>
                                    </Grid>
                                </MKBox>
                            </MKBox>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </MKBox>
    );
}

export default LeadForm;