import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

import MKBox from "components/MKBox";

import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

function Counters() {
  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              count={400}
              suffix="+"
              title="Total Students"
              description="Join IMCHESS Academy, where over 400+ students are sharpening their skills under the guidance of an experienced IM coach."
            />
          </Grid>
          <Grid item xs={12} md={4} display="flex">
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, mx: 0 }} />
            <DefaultCounterCard
              count={20}
              suffix="+ years"
              title="Total Expirence"
              description="Over 2 decades of chess experience bring invaluable insights, strategies, and techniques to every lesson, ensuring that you receive the highest quality coaching crafted to your skill level"
            />
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, ml: 0 }} />
          </Grid>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              count={5}
              suffix="+ years"
              title="Total Coaching Expirence"
              description="Benefit from 5+ years of coaching expertise in chess refinement. Our seasoned team offers personalized instruction and proven strategies for success. Join us to unlock your full potential on the chessboard."
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Counters;
