import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MKBox from 'components/MKBox';
import { Container, Grid } from '@mui/material';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import MKTypography from 'components/MKTypography';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function FagsAccordions() {
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    return (
        <MKBox component="section" pt={1} my={6}>
            <Container>
                <Grid container alignItems="center">
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className="accordion-root" >
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" className={`${expanded === 'panel1' && 'greenBackground'}`}
                            expandIcon={<KeyboardDoubleArrowRightOutlinedIcon />}>
                            <MKTypography variant="h6" color="dark">
                                How do I enroll in your academy?
                            </MKTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <MKTypography variant="h6" color="text">
                                Fill the Enrollment Form:
                            </MKTypography>
                            <Container>
                                <MKTypography variant="body2" color="text">
                                    <ul>
                                        <li>
                                            Visit this link to access the enrollment form.
                                        </li>
                                        <li>
                                            Fill out the required details, including your name, age, contact information, and any relevant preferences or previous experience.
                                        </li>
                                    </ul>
                                </MKTypography>
                            </Container>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} className="accordion-root" >
                        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" className={`${expanded === 'panel2' && 'greenBackground'}`}
                            expandIcon={<KeyboardDoubleArrowRightOutlinedIcon />}>
                            <MKTypography variant="h6" color="dark">
                                What resources do you provide to students, such as chess books, software, or online resources?
                            </MKTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Container>
                                <MKTypography variant="body2" color="text">
                                    <ul>
                                        <li>
                                            <MKTypography variant="h6" color="text">
                                                Suggests chess books :
                                            </MKTypography>
                                            which covers various aspects of the game, including openings, tactics, strategy, endgames, and famous games by master players.
                                        </li>
                                        <li>
                                            <MKTypography variant="h6" color="text">
                                                Online Material on Lichess :
                                            </MKTypography>
                                            We leverage the power of online platforms like Lichess to supplement our students' learning experience.
                                            Lichess provides a wealth of resources, including interactive puzzles, instructional videos, and analysis tools.
                                            We encourage our students to explore these resources to reinforce their understanding of chess principles and improve their skills.
                                        </li>
                                        <li>
                                            <MKTypography variant="h6" color="text">
                                                Sharing Notes for Special Cases:
                                            </MKTypography>
                                            In addition to traditional teaching methods,
                                            we provide supplementary materials in the form of notes, articles, and study guides for special cases or topics that require in-depth explanation.
                                        </li>
                                        <li>
                                            <MKTypography variant="h6" color="text">
                                                Summary:
                                            </MKTypography>
                                            By offering a combination of chess books, online resources on platforms like Lichess, and supplementary materials for special cases,
                                            we aim to provide our students with a well-rounded learning experience that caters to their individual needs and preferences.
                                            Our goal is to empower them to become confident and skilled chess players who can continue to grow and excel both on and off the board.
                                        </li>
                                    </ul>
                                </MKTypography>
                            </Container>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} className="accordion-root" >
                        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header" className={`${expanded === 'panel3' && 'greenBackground'}`}
                            expandIcon={<KeyboardDoubleArrowRightOutlinedIcon />}>
                            <MKTypography variant="h6" color="dark">
                                Do you organize tournaments or competitions for students to participate in?
                            </MKTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Container>
                                <MKTypography variant="body2" color="text">
                                    <ul>
                                        <li>
                                            Yes, we organize tournaments and competitions for our students to participate in.
                                        </li>
                                        <li>
                                            We host tournaments exclusively for students who are currently enrolled in our courses.
                                            These tournaments provide a platform for students to test their skills and apply what they've learned in a competitive setting among their peers.
                                        </li>
                                    </ul>
                                </MKTypography>
                            </Container>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} className="accordion-root" >
                        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header" className={`${expanded === 'panel4' && 'greenBackground'}`}
                            expandIcon={<KeyboardDoubleArrowRightOutlinedIcon />}>
                            <MKTypography variant="h6" color="dark">
                                Do you offer trial classes for new students?
                            </MKTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Container>
                                <MKTypography variant="body2" color="text">
                                    <ul>
                                        <li>
                                            <MKTypography variant="h6" color="text">
                                                15 minutes 1:1 consultation and a right guidance
                                            </MKTypography>
                                            While we don't provide trial or demo sessions, we offer a 15-minute one-on-one consultation for new students.
                                        </li>
                                        <li>
                                            This personalized guidance allows them to discuss their goals and receive tailored advice from our instructors.
                                        </li>
                                    </ul>
                                </MKTypography>
                            </Container>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} className="accordion-root" >
                        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header" className={`${expanded === 'panel5' && 'greenBackground'}`}
                            expandIcon={<KeyboardDoubleArrowRightOutlinedIcon />}>
                            <MKTypography variant="h6" color="dark">
                                Do you provide any support or guidance for students interested in pursuing chess competitively?
                            </MKTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <MKTypography variant="h6" color="text">
                                Yes, we do give the below support and guidance to students
                            </MKTypography>
                            <Container>
                                <MKTypography variant="body2" color="text">
                                    <ul>
                                        <li>
                                            Advanced Coaching
                                        </li>
                                        <li>
                                            Tournament Preparation
                                        </li>
                                        <li>
                                            Performance Analysis
                                        </li>
                                        <li>
                                            Goal Setting
                                        </li>
                                        <li>
                                            Networking Opportunities
                                        </li>
                                    </ul>
                                </MKTypography>
                            </Container>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')} className="accordion-root" >
                        <AccordionSummary aria-controls="panel6d-content" id="panel6d-header" className={`${expanded === 'panel6' && 'greenBackground'}`}
                            expandIcon={<KeyboardDoubleArrowRightOutlinedIcon />}>
                            <MKTypography variant="h6" color="dark">
                                What is the average class size?
                            </MKTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <MKTypography variant="h6" color="text">
                                The average class size varies depending on the course structure:
                            </MKTypography>
                            <Container>
                                <MKTypography variant="body2" color="text">
                                    <ul>
                                        <li>
                                            <MKTypography variant="h6" color="text">
                                                For individual batch,
                                            </MKTypography>
                                            We include 1 or maximum 2 students.(preferd for norms or increasing rating above 2000)
                                        </li>
                                        <li>
                                            <MKTypography variant="h6" color="text">
                                                For group batches,
                                            </MKTypography>
                                            We typically have 10 students per batch.
                                            This size allows us to maintain quality instruction and facilitate meaningful one-on-one interaction among students and coach.
                                        </li>
                                        <li>
                                            <MKTypography variant="h6" color="text">
                                                For basic course batches,
                                            </MKTypography>
                                            We include slightly larger groups of 15-20 students.
                                            This accommodates a broader range of learners while still ensuring personalized attention and effective learning outcomes.
                                        </li>
                                    </ul>
                                </MKTypography>
                            </Container>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')} className="accordion-root" >
                        <AccordionSummary aria-controls="panel7d-content" id="panel7d-header" className={`${expanded === 'panel7' && 'greenBackground'}`}
                            expandIcon={<KeyboardDoubleArrowRightOutlinedIcon />}>
                            <MKTypography variant="h6" color="dark">
                                Can adults enroll in your classes, or are they primarily for children and teenagers?
                            </MKTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <MKTypography variant="h6" color="text">
                                Absolutely! Chess knows no age limit, and our classes welcome enthusiasts of all ages.
                            </MKTypography>
                            <Container>
                                <MKTypography variant="body2" color="text">
                                    <ul>
                                        <li>
                                            Whether you're an adult looking to enhance cognitive skills, sharpen strategic thinking, or simply enjoy the game, our courses cater to everyone.
                                        </li>
                                        <li>
                                            Chess offers a multitude of benefits, including improving intelligence, intuition, focus, and tactical skills, making it an enriching experience for individuals of any age group.
                                            So, don't hesitate to join us regardless of your age; chess is truly for everyone!
                                        </li>
                                    </ul>
                                </MKTypography>
                            </Container>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')} className="accordion-root" >
                        <AccordionSummary aria-controls="panel8d-content" id="panel8d-header" className={`${expanded === 'panel8' && 'greenBackground'}`}
                            expandIcon={<KeyboardDoubleArrowRightOutlinedIcon />}>
                            <MKTypography variant="h6" color="dark">
                                Do you provide any specialized training for specific aspects of the game?
                            </MKTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <MKTypography variant="h6" color="text">
                                Certainly! We offer specialized training courses tailored to specific aspects of the game:
                            </MKTypography>
                            <Container>
                                <MKTypography variant="body2" color="text">
                                    <ul>
                                        <li>
                                            Openings
                                        </li>
                                        <li>
                                            Tactics
                                        </li>
                                        <li>
                                            Endgames
                                        </li>
                                        <li>
                                            Customized Courses: Personalized instruction tailored to individual needs.
                                        </li>
                                    </ul>
                                </MKTypography>
                            </Container>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Container>
        </MKBox>
    );
}
