// @mui material components
import { Icon } from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// React components
import MKBox from "components/MKBox";

// Images
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import { useNavigate } from "react-router-dom";

function BriefAboutus() {
    const navigate = useNavigate();
    return (
        <MKBox component="section" py={6} my={6}>
            <Container>
                <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
                    <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
                        <Grid container spacing={3}>
                            <MKBox pt={12} pb={2} px={2} lineHeight={1}>
                                <MKTypography variant="h3" color="#fff" gutterBottom>
                                    About IMCHESS!
                                </MKTypography>
                                <MKTypography variant="body2" color="black" opacity={0.8}>
                                    IMCHESS Academy is your premier destination for high-quality chess education.
                                    With expert instruction from International Master, personalized learning, and a focus on strategic development, we're here to help you reach your chess goals.
                                    Join our community today and unlock your full potential in the game of kings.
                                </MKTypography>
                            </MKBox>

                        </Grid>
                        <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
                            <Grid item xs={12} md={6}>
                                <MKButton variant="gradient"
                                    onClick={() => navigate('/about-us')}
                                    color="info" sx={{
                                        width: "max-content",
                                        display: "flex",
                                        alignItems: "center",

                                        "& .material-icons-round": {
                                            transform: `translateX(3px)`,
                                            transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                                        },

                                        "&:hover .material-icons-round, &:focus .material-icons-round": {
                                            transform: `translateX(6px)`,
                                        },
                                    }}>
                                    Know More About Us <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                                </MKButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQiLXdStKc7gEIuNshQpQJYh-oc84QVwV9mX-6rIkNB5Q&s" alt="about-img" />
                    </Grid>
                </Grid>
            </Container>
        </MKBox>
    );
}

export default BriefAboutus;
